const globalHeader = {
  home: "Home",
  service: "Service",
  residentialNav: "Residential",
  commercialNav: "Commercial & Industrial",
  term: "Term & Conditional",
  privacy: "Privacy Policy",
  contact: "Contact",
  contactUS: {
    titleBar:"SolarAccess - Contact",
    labelContact: "CONTACT US",
    getInTouch: "GET IN TOUCH",
    titleContact:
      "We are here to answer any questions or concerns you may have. Whether you need help finding the right product, have a technical issue, or just want to share feedback, we are here to help.",
    contentContact: "Contact US",
    visit: "VISIT OUR SHOWROOM",
    letsAnswer: "LET'S ANSWER YOUR QUERIES",
    labelFirst: "First Name ",
    labelLast: "Last Name",
    labelNoTlp: "No. Telephone",
    labelMessage: "Message",
  },
  landing: {
    titleBar :"SolarAccess - One stop solution to monitor your Solar PV System",
    welcome: "Welcome to ",
    oneStop: "One-stop solution to monitor your Solar PV System",
    titleAbout: "About SolarAccess",
    contentAbout:
      " With the new SolarAccess apps, you can always visualize your solar power generation, export, and grid supplied power. Access on your Energy System at all times",
    titleService: "About The Service",
    titleService2: "Residential",
    contentResidential: `ATW Solar apps and
            SolarAccess apps both serve to
            our customer who are
            committed in clean energy
            future`,
    titleCommercial: "Commercial & Industrial",
    contentCommercial:
      "Online portal SolarAccess it has feature the provides convenience for customer.",
    titleBenefit: "Benefit",
    titleClient: "Clients US",
    titleFaq: "Frequently Asked Questions",
    titleFaq1: "What is SolarAccess?",
    contentFaq1:
      "SolarAccess is an application aiming to monitor your solar panels' productivity. You can see how much savings you can get by installing solar panels and compare it with your PLN electricity consumption.",
    titleFaq2: "How are my solar panels monitored via SolarAccess?",
    contentFaq2:
      "You can contact us via the contact information below to apply for our monitoring devices installation, our devices will monitor your solar panel performance and link it to your account.",
    titleFaq3: "How to register a SolarAccess account?",
    contentFaq3_1: "Download SolarAccess on Play Store or App Store",
    contentFaq3_2: "Open the Application, and click Button Register Now",
    contentFaq3_3:
      "Make sure your email is valid and active because a verification code will be sent",
    contentFaq3_4: "Verify your account with the code sent to your email",
    contentFaq3_5:
      "If the verification is successfull, confirm to the field technician team the email you registered, your   account will be linked with our monitoring devices",
    contentFaq3_6: "Try login with email you registered",
    contentFaq3_7:
      "Successful, the solar panels in your home can already be monitored on SolarAccess",
    titleFaq4: "Can i delete my account?",
    contentFaq4_1: "Yes, your account can be deleted with the following steps:",
    contentFaq4_2: "Write an email to it@atw-solar.id with Subject:",
    contentFaq4_3:
      "Request to Delete SolarAccess Account - [Your registered email]",
    contentFaq4_4:
      "Write in body of the email the reason you want to delete the account (want to stop using SolarAccess, want to change account and create with another email, and etc).",
    contentFaq4_5:
      "Our team will review and confirm via email regarding your account deletion within 3 working days",
    contentFaq4_6:
      "After you reply that you are sure you want to delete your account, your account will be deleted within 2 working day. We will also send you an email notification if the deletion of your account is successfull.",
    contentFaq4_7: "Your account has been successfully deleted.",
    titleContact:
      "We are here to answer any questions or concerns you may have. Whether you need help finding the right product, have a technical issue, or just want to share feedback, we are here to help.",
    contentContact: "Contact US",
  },
  residential: {
    solarAccess: "SolarAcces - Residential",
    energiDisplay:"block",
    energiDisplayIndo:"none",
    aboutResindetial: `SolarAccess apps serve to
            our customer who are
            committed in clean energy
            future,`,
    titleSmartEnergi: "Smart Energy Meter",
    contentSmartEnergi: ` 
            Our package included with this device, helps to connect Solar PV
            system to your gadget and you will be able to monitor the system
            anywhere and anytime`,
    titleEnergiHistory: "Energy System History",
    titleRealMonitoring: "Real Time Monitoring",
    contentRealMonitoring: `Visualize on your real-time Solar PV
            system and electricity usage`,
    titleEnergiUsage: `Energy Usage + Energy
            Generation History`,
    contentEnergiUsage: `Get a daily, monthly, yearly, and historical
            figures on your electrical system.`,
    titleSystemBenefit: "Know your PV System Benefits",
    contentSystemBenefit: `View your cost savings and carbon
            footprints`,
    titleForecast: "Forecast your Electricity Bills",
    contentForecast: `Know your actual electricity usage and
            forecast the bills at the end of month`,
    shareFamily: "Share it with your Family",
    contentshareFamily: `Not only you, your family can also
            access and know the benefits in Solar
            PV system`,
    titleProduct: "Get Access to our Products",
    contentProduct: `See our latest products and news in
            ATW Solar`,
    titleBenefitValue: "Benefit and Value",
    contentBenefitValue: `By adding our devices in your house, you can monitor and enjoy many
            benefits on installing Solar PV System with our products.`,
    frendly: "Frendly",
    simple: "Simple",
    insight: "Insightful",
    trusted: "Trusted",
    renewed: "Renewed",
    access: "Access anywhere",
    titleHowToUse: "how to use SolarAccess residential",
  },
  commercial: {
    titleCommercial: "SolarAccess - Commercial & Industrial",
    aboutCommercial: `Online portal SolarAccess it has feature the provides convenience for customer. Development with innovations continues to be done so that customers can experience the benefits and comfort.
      Performance indicators for PV are provided automatically and extensive, configurable reporting.
      SolarAccess is the right solution for efficient monitoring and management of PV operations for industrial-scale solar power systems.`,
    titleMonitoring: "Monitoring",
    contentMonitoring:
      "Graphical user interface with interactive and easy-to-understand visuals for monitoring your PV system performance.",
    contentMonitoring2:
      "Dashboard Main provides a full overview of the current state of the PV.",
    contentMonitoring3: "Summary against parameters mainly operates PV",
    contentMonitoring4:
      "Knowing the current weather conditions from each site.",
    contentMonitoring5:
      "A Graph of Solar Energy that can be viewed daily, monthly, and yearly.",
    contentMonitoring6:
      "The comparison between the energy generated by Photovoltaic Solar Power and energy from the Grid is shown in the current graph and percentages.",
    titleOperation: "Operation Management",
    contentOperation: "Access to all data on core components PV",
    contentOperation1: " Management of PV sites connected to your account",
    contentOperation2:
      "User Management to manage each account connected to the PV site.",
    titleReporting: "Reporting",
    contentReporting1: "Individual Report on Site Operations for One Month",
    contentReporting2: "Data Generation Performance Ratio to PV Operation",
    contentReporting3:
      "Comparison of Ideal Value of PV System to Actual Condition.",
    contentReporting4: "Raw and main data export in CSV format",
    titleAlarm: "Alarm",
    contentAlarm:
      "Getting the latest alarm information on the operation incident PV",
    contentAlarm2:
      "Explanation of the location of the equipment and the time of the alarm incident",
    contentAlarm3:
      "Access the history of all Alarms that have occurred on the site PV",
    titleBenefit: "Benefit",
    contentBenefit:
      "Knowing the latest solar power operations anytime, anywhere.",
    contentBenefit2: "Access to overall data and operations analysis PV",
    contentBenefit3:
      "Save time in identifying issues in power plant operations to maximize energy generation PV",
    contentBenefit4: "We rely on easy data access to our system.",
  },
  termConditional: {
    titleTerm: "Term & Conditional",
    titleTermSolarAccess:
      "With the SolarAccess PV Management System (“SolarAccess”) for iOS and Android devices based on the following terms of use, PT. ATW Solar Residensial (“ATW”) makes available services and data (collectively “services”) e.g. in connection with the collection, management and presentation of the data of energy technology devices, systems and plants (“systems”) via the App Store (iOS) and Google Play (Android). The prerequisite is that the user is the owner of the PV system, which is using ATW products.",
    li1: "To use the SolarAccess to manage and monitor connected PV devices, a user needs to inform ATW administrators to obtain an account and password after finishing the sensors installation. The user needs to keep the password secure to prevent the disclosure of this password to unauthorized third parties.",
    titleHowToUse: "How To Use",
    li2: "To use the app, it is necessary to download and install the app from the App Store. The user must therefore have an account with the App Store. By downloading the app from the App Store, the user receives a personal, limited, simple, non-transferable license, limited to the duration of the user contract, to install the app on mobile devices linked to the App Store account",
    li3: "To deregister an account, contact the SolarAccess administrator or call the service hotline. After logging in to the system using an account, a user can request to create subaccounts (including the lower-level co-owner account) and configure the initial passwords for the subaccounts. The user can set whether to require password change upon the first login of a subaccount. For details about how to create and deregister a subaccount, contact the corresponding SolarAccess administrators",
    li4: "Mobile data connection is required to use the SolarAccess. ATW expressly points out that, depending on the usage behavior, the app will sometimes require a high data volume and the user may incur costs payable to the operator of the mobile data connection. These costs shall be borne by the user.",
    li5: "If ATW suffers any loss because the user fails to obtain the explicit consent of the device owner or uses the device beyond the scope, the user agrees and promises to bear all losses.",
    titleUseLimitations: "Use Limitations",
    li6: "The SolarAccess provided for use was developed by ATW and is protected by copyright laws. ATW possesses all the rights of use and distribution. The user may only use and exploit the information and results of this online service within the framework of these Terms of Use. The user may process this information for personal use only. The user may not add app elements or change, delete or modify elements of the SolarAccess in any other form without the prior express written consent of ATW. In particular, the user is not permitted to copy, extract or otherwise use graphic elements or attempt to decompile the source code of the SolarAccess. Uses of the information and results obtained from this service beyond the above mentioned are only permitted with the expressed written consent of ATW.",
    titleUser: "User's Own Content",
    contentUser:
      "The user is not entitled to post submitted content, nor is he/she allowed to permanently store content on the SolarAccess. ATW is not responsible for the content, correctness, or the form of the posted information. In addition, the user undertakes to protect and to refrain from violating the rights of third parties, in particular trademark rights, copyright, and personal rights. The posting of content which is violent, pornographic or discriminatory, or other content which is illegal or which offends common decency is prohibited.",
    titleReference: "References & Links",
    contentReference:
      "The information posted on this Apps may include appropriately highlighted links or references to third-party websites. The supplier who makes this content available shall have sole responsibility for it. ATW provides only access to this content. ATW has no influence on the current appearance, content, or authorship of linked/referenced sites. For this reason, ATW hereby expressly distances itself from all content of linked/referenced sites. The person providing illegal, inaccurate, or incomplete contents is liable for any damage, especially those arising from the use or non-use of third-party information, and not merely the person who refers to the publication in question by placing links on the website.",
    titleDisclamer: "Disclaimer ",
    contentDisclamer1:
      "The technical data used by ATW sensors is continuously checked and, if necessary, adapted. However, defects and mistakes may still arise. We accept no responsibility for the completeness and/or correctness of the data (the values do not represent guaranteed features). Huawei shall not assume any responsibility for the accuracy, correctness, completeness, or quality of the information, documents, indicated results, and revenue information.",
    contentDisclamer2:
      "ATW assumes no responsibility for any errors or omissions in the specified information to which a reference was made there, or which is linked. All liability claims against ATW relating to damages of material or immaterial nature, which were caused by the use or non-use of the provided information or due to the use of erroneous or incomplete information, are excluded provided that no intentional or grossly negligent culpability exists on the part of ATW.",
    contentDisclamer3:
      "In particular, ATW does not accept any liability for defects and consequential damage, for incorrect profit or self-consumption calculations, for loss of profits, for loss of use, for damage to used hardware, for loss of data, or for interruption of operation or other damage. Liability for data loss is limited to the typical cost to recover the data which would apply when making regular backups appropriate to the risk, unless there was an intentional or grossly negligent act on the part of ATW.",
    contentDisclamer4:
      "It is the user's sole area of responsibility to check the content of the results gained from the use of the SolarAccess and their suitability for the particular purpose of use. ATW expressly states that the reimbursement, electricity bill, and energy usage information posted on the SolarAccess is solely for the purpose of the user and, in particular, is not intended as the basis of a settlement/billing of accounts or to produce evidence of claims concerning energy savings, energy usage, and the feed-in of energy towards network/grid operators.",
    contentDisclamer5: `The user does not have any claim to the availability of the SolarAccess nor to 100% data availability. All information, content, materials and services made available to you through the SolarAcess are provided by ATW on an "AS IS" basis, unless otherwise specified in the agreement.`,
    contentDisclamer6:
      "ATW makes no representations or warranties of any kind, express or implied, as to the operation of the SolarAccess or the information, content, materials, products (including any software) or services included on or otherwise made available to you through the SolarAccess, unless otherwise specified in writing. You expressly agree that your use of the SolarAccess is at your sole risk. To the full extent permissible by applicable law, ATW disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose.",
    titleQuality: "Changes to the Content and Quality of the App",
    contentQuality:
      "ATW reserves the right to change, add to or delete the design and content of the SolarAccess or to cease publication of the services temporarily or permanently. Users are not entitled to the maintenance or provision of a certain state or functional scope of the SolarAccess.",
    contentQuality1:
      "ATW may, from time to time, issue new versions of these Terms. A new version becomes valid and shall replace the previous version provided that you do not object to such new version within 3 months after having been provided a new version, and provided that ATW has notified you of your right to object within 2 months. ATW's notification shall contain an express instruction that the user's failure to object the new version shall be deemed as his/her agreement with the new version becoming binding. Should a user object the new version, the Terms of Use shall automatically terminate with immediate effect, and the user must immediately refrain from further using the SolarAccess.",
    contentQuality2:
      "ATW is keen to keep the app in a defect-free condition in line with the state of the art at all times. However, ATW is unable to ensure that the app is free from errors.",
    titleIndustrial: "Industrial Property Rights and Copyrights",
    contentIndustrial:
      "The content of the app and the affiliated ATW websites and services are the exclusive property / exclusive legal property of ATW and, where relevant, the respective licensor. ATW possesses all use and distribution rights. The content is protected by national and international law, particularly copyright. Unauthorized distribution, reproduction, transformation, processing, use or other violation of ATW industrial property rights and copyrights will be subject to civil and/or criminal action.",
    titleAplicable: "Applicable Law",
    contentAplicable:
      "In addition to the adjustment of rules (conventions) for the sale of goods, the rights and obligations of users and service providers in these Terms of Use shall be adjusted by the local entity law. No terms, however, can damage your legal rights as a resident of a place.",
    titleSeverAbility: "Severability Clause",
    contentSeverablility:
      "Should parts or individual formulations of these Terms of Use no longer, or not fully comply with applicable laws, this shall have no effect on the remaining parts in terms of their content or validity. The ineffective provision shall be replaced with a regulation to be determined by means of interpretation, which comes as close as possible to the financially and actually intended regulation in a legally effective way.",
  },
  policyPrivacy: {
    titleBar :"SolarAccess - Policy Privacy",
    titlePolicy: "PRIVACY POLICY",
    contentPolicy: `Your privacy is important to us. It is PT ATW Solar Indonesia's policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including across our website,`,
    contentPolicy2: `, and other sites we own and operate.
      This policy is effective as of 9 December 2021 and was last updated on 9 December 2021.`,
    titleInformation: "Information We Collect",
    contentInformation:
      "Information we collect includes both information you knowingly and actively provide us when using or participating in any of our services and promotions, and any information automatically sent by your devices in the course of accessing our products and services. ",
    titleLog: "Log Data",
    contentLog: `When you visit our website, our servers may automatically log the standard data provided by your web browser. It may include your device’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, other details about your visit, and technical details that occur in conjunction with any errors you may encounter.
      Please be aware that while this information may not be personally identifying by itself, it may be possible to combine it with other data to personally identify individual persons.`,
    titlePersonal: "Personal Information",
    contentPersonal:
      " We may ask for personal information which may include one or more of the following:",
    name: "Name",
    email: "Email",
    phone: "Phone/mobile number",
    titleLegalisi:
      "Legitimate Reasons for Processing Your Personal Information",
    contentLegalisi:
      "We only collect and use your personal information when we have a legitimate reason for doing so. In which instance, we only collect personal information that is reasonably necessary to provide our services to you.",
    titleCollection: "Collection and Use of Information",
    contentCollection:
      "We may collect personal information from you when you do any of the following on our website:",
    contentCollection1:
      "Use a mobile device or web browser to access our content.",
    contentCollection2:
      "Contact us via email, social media, or on any similar technologies.",
    contentCollection3: "When you mention us on social media.",
    contentCollection4:
      "We may collect, hold, use, and disclose information for the following purposes, and personal information will not be further processed in a manner that is incompatible with these purposes:",
    contentCollection5: "to contact and communicate with you.",
    contentCollection6:
      "for analytics, market research, and business development, including to operate and improve our website, associated applications, and associated social media platforms.",
    contentCollection7:
      "Please be aware that we may combine information we collect about you with general information or research data we receive from other trusted sources.",
    titleSecurity: "Security of Your Personal Information",
    contentSecurity: `When we collect and process personal information, and while we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.
      Although we will do our best to protect the personal information you provide to us, we advise that no method of electronic transmission or storage is 100% secure, and no one can guarantee absolute data security. We will comply with laws applicable to us in respect of any data breach.
      You are responsible for selecting any password and its overall security strength, ensuring the security of your own information within the bounds of our services.`,
    titleHowLong: `How Long We Keep Your Personal Information`,
    contentHowLong: `We keep your personal information only for as long as we need to. This time period may depend on what we are using your information for, in accordance with this privacy policy. If your personal information is no longer required, we will delete it or make it anonymous by removing all details that identify you.
      However, if necessary, we may retain your personal information for our compliance with a legal, accounting, or reporting obligation or for archiving purposes in the public interest, scientific, or historical research purposes or statistical purposes.
   `,
    titleChildren: "Children’s Privacy",
    contentChildrend:
      "We do not aim any of our products or services directly at children under the age of 13, and we do not knowingly collect personal information about children under 13.",
    titleDisclosure: "Disclosure of Personal Information to Third Parties",
    contentDisclosure: "We may disclose personal information to:",
    contentDisclosure1: "a parent, subsidiary, or affiliate of our company",
    contentDisclosure2:
      "third party service providers for the purpose of enabling them to provide their services, for example, IT service providers, data storage, hosting and server providers, advertisers, or analytics platforms",
    contentDisclosure3: "our employees, contractors, and/or related entities",
    contentDisclosure4: "our existing or potential agents or business partners",
    contentDisclosure5:
      " sponsors or promoters of any competition, sweepstakes, or promotion we run",
    contentDisclosure6:
      "courts, tribunals, regulatory authorities, and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise, or defend our legal rights",
    contentDisclosure7:
      "third parties, including agents or sub-contractors, who assist us in providing information, products, services, or direct marketing to you",
    contentDisclosure8: "third parties to collect and process data",
    titleThird: "Disclosure of Personal Information to Third Parties",
    contentThird:
      "The personal information we collect is stored and/or processed where we or our partners, affiliates, and third-party providers maintain facilities. Please be aware that the locations to which we store, process, or transfer your personal information may not have the same data protection laws as the country in which you initially provided the information. If we transfer your personal information to third parties in other countries: (i) we will perform those transfers in accordance with the requirements of applicable law; and (ii) we will protect the transferred personal information in accordance with this privacy policy.",
    titlePersonalInformation:
      "Your Rights and Controlling Your Personal Information",
    contentPersonalInformation: `You always retain the right to withhold personal information from us, with the understanding that your experience of our website may be affected. We will not discriminate against you for exercising any of your rights over your personal information. If you do provide us with personal information you understand that we will collect, hold, use and disclose it in accordance with this privacy policy. You retain the right to request details of any personal information we hold about you.
   If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us.
   If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time. We will provide you with the ability to unsubscribe from our email-database or opt out of communications. Please be aware we may need to request specific information from you to help us confirm your identity.
   If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant, or misleading, please contact us using the details provided in this privacy policy. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading, or out of date.
   If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact a regulatory body or data protection authority in relation to your complaint.`,
    titleCookies: "Use of Cookies",
    contentCookies:
      "We use “cookies” to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on preferences you have specified.",
    titleLimits: "Limits of Our Policy",
    contentLimits:
      "Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.",
    titleChange: "Changes to This Policy",
    contentChange: `At our discretion, we may change our privacy policy to reflect updates to our business processes, current acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy, we will post the changes here at the same link by which you are accessing this privacy policy.
   If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as applicable, any new uses of your personal information.`,
   titleContact:"Contact US",
   contentPrivacyContact: `For any questions or concerns regarding your privacy, you may contact us using the following details:
   ATW Solar Marketing Team`,
  },
  footer:{
    titleContact:"We are here to answer any questions or concerns you may have. Whether you need help finding the right product, have a technical issue, or just want to share feedback, we are here to help.",
    contentContact:"Contact US",
    contentFooter:"Our Services",
    contentFooterOur1:"Our Team",
    contentFooterOur2:"Our Partner",
    contentFooterOur3:"Our Product & Technology",
    contentFooterOur4:"Portfolio",
    contentFooterOur5:"News & Blog",
    contentFooter2:"Our Social Networks"
  }
};

// reducer
const rootHeader = (state = globalHeader, action) => {
  // console.log("action tipe",action.type)
  if (action.type === "INDONESIA") {
    return {
      home: "Beranda",
      service: "Layanan",
      residentialNav: "Residensial",
      commercialNav: "Komersial & Industri",
      term: "Syarat & Ketentuan",
      privacy: "Kebijakan Privasi",
      contact: "Hubungi",
      contactUS: {
        titleBar:"SolarAccess - Hubungi",
        labelContact: "HUBUNGI KAMI",
        getInTouch: "SILAHKAN HUBUNGI",
        titleContact:
          "Kami di sini untuk menjawab setiap pertanyaan atau masalah yang mungkin Anda miliki. Apakah Anda memerlukan bantuan untuk menemukan produk yang tepat, memiliki masalah teknis, atau hanya ingin berbagi umpan balik, kami siap membantu.",
        contentContact: "Hubungi Kami",
        visit: "KUNJUNGI SHOWROOM KAMI",
        letsAnswer: "MARI KITA JAWAB PERTANYAAN ANDA",
        labelFirst: "Nama Depan",
        labelLast: "Nama Belakang",
        labelNoTlp: "No. Telepone",
        labelMessage: "Pesan",
      },
      landing: {
        titleBar:"SolarAccess - Solusi satu atap untuk memantau PLTS anda",
        welcome: "Selamat Datang di ",
        oneStop: "Solusi satu atap untuk memantau PLTS anda",
        titleAbout: "Tentang SolarAccess",
        contentAbout:
          "Dengan aplikasi SolarAccess baru, Anda selalu dapat memvisualisasikan produksi, ekspor, dan daya listrik yang diberikan oleh jaringan listrik Anda. Akses sistem energi Anda kapan saja.",
        titleService: "Tentang Layanan",
        titleService2: "Residensial",
        contentResidential: `Aplikasi ATW Solar dan SolarAccess berdua melayani pelanggan kami yang berkomitmen untuk masa depan energi bersih.`,
        titleCommercial: "Komersial & Industri",
        contentCommercial:
          "Portal Online SolarAccess ini memiliki fitur menyediakan kenyamanan bagi pelanggan.",
        titleBenefit: "Keuntungan",
        titleClient: "Klien Kami",
        titleFaq: "Pertanyaan Umum",
        titleFaq1: "Apa itu SolarAccess?",
        contentFaq1:
          "SolarAccess adalah aplikasi yang bertujuan untuk memantau produktivitas PLTS Anda. Anda dapat melihat berapa banyak penghematan yang bisa Anda dapatkan dengan menginstal PLTS dan membandingkannya dengan konsumsi listrik PLN Anda.",
        titleFaq2:
          "Bagaimana surya saya panel-panelnya dipantau melalui SolarAccess?",
        contentFaq2:
          "Anda dapat menghubungi kami melalui informasi kontak di bawah ini untuk mendaftar instalasi perangkat pemantauan kami, perangkat kami akan memantau performa PLTS Anda dan menghubungkannya ke akun Anda.",
        titleFaq3: "Bagaimana cara mendaftarkan akun SolarAccess?",
        contentFaq3_1: "Unduh SolarAccess di Play Store atau Apps Store",
        contentFaq3_2: "Buka aplikasi, dan klik tombol register now",
        contentFaq3_3:
          "Pastikan Email anda valid dan aktif karena untuk kode verifikasi",
        contentFaq3_4: "Berusaha Masuk dengan email yang anda daftar",
        contentFaq3_5:
          "Jika verifikasi berhasil, konfirmasikan ke tim teknisi lapangan email yang Anda daftarkan, akun Anda akan terhubung dengan perangkat pemantauan kami.",
        contentFaq3_6: "Coba masuk dengan email yang kamu daftarkan.",
        contentFaq3_7:
          "Berhasil, PLTS di rumah Anda sudah dapat dipantau di SolarAccess.",
        titleFaq4: "bisakah saya menghapus akun saya?",
        contentFaq4_1:
          "bisa, akun kamu bisa di hapus dengan mengikuti langkah-langkah:",
        contentFaq4_2: "Menuliskan email ke it@atw-solar.id dengan subject ",
        contentFaq4_3:
          "Permintaan untuk menghapus akun SolarAccess - [email yang kamu daftarkan]",
        contentFaq4_4:
          "Menuliskan di dalam badan email alasan anda ingin menghapus akun (ingin berhenti menggunakan SolarAccess, ingin merubah akun dan membuat dengan email yang berbeda, dan lain-lain)",
        contentFaq4_5:
          "Team kami akan meninjau dan konfirmasi melalui email mengenai akun anda penghapusan dalam 3 hari kerja",
        contentFaq4_6:
          "Setelah Anda menanggapi bahwa Anda yakin ingin menghapus akun Anda, akun Anda akan dihapus dalam 2 hari kerja. Kami juga akan mengirimkan pemberitahuan email jika penghapusan akun Anda berhasil.",
        contentFaq4_7: "Akun Anda telah berhasil dihapus.",
        titleContact:
          "Kami di sini untuk menjawab setiap pertanyaan atau masalah yang mungkin Anda miliki. Apakah Anda memerlukan bantuan untuk menemukan produk yang tepat, memiliki masalah teknis, atau hanya ingin berbagi umpan balik, kami siap membantu.",
        contentContact: "Hubungi Kami",
      },
      residential: {
        solarAccess: "SolarAcces - Residensial",
        energiDisplay:"none",
        energiDisplayIndo:"block",
        aboutResindetial:
          "SolarAccess adalah aplikasi yang melayani kepada pelanggan kami yang berkomitmen dalam energi bersih",
        titleSmartEnergi: "Meter Energi Cerdas",
        contentSmartEnergi:
          "Paket kami termasuk dengan perangkat ini, membantu untuk menghubungkan Sistem PLTS ke perangkat Anda dan Anda akan dapat memonitor sistem ini dimanapun dan kapanpun.",
        titleEnergiHistory: "Riwayat Sistem Energi",
        titleRealMonitoring: "Monitoring Real Time",
        contentRealMonitoring:
          "Visualisasikan Sistem PLTS Solar dan Penggunaan Listrik Anda secara Real-Time.",
        titleEnergiUsage: "Riwayat Penggunaan + Pembangkitan Energi",
        contentEnergiUsage:
          "Visualisasikan sistem PLTS Solar dan penggunaan listrik Anda secara real-time.",
        titleSystemBenefit: "Kenali Manfaat Sistem PLTS Anda",
        contentSystemBenefit: "Lihat penghematan biaya dan jejak karbon Anda",
        titleForecast: "Ramalkan Tagihan Listrik Anda",
        contentForecast: `Ketahui penggunaan listrik aktual Anda dan perkirakan tagihan pada akhir bulan.`,
        shareFamily: "Bagikan dengan keluargamu",
        contentshareFamily:
          "Bukan hanya Anda, keluarga Anda juga dapat mengakses dan mengetahui manfaat dari Sistem PLTS Solar.",
        titleProduct: "Dapatkan Akses ke Produk Kami",
        contentProduct: "Lihat produk dan berita terbaru kami di ATW Solar.",
        titleBenefitValue: "Manfaat dan Nilai",
        contentBenefitValue:
          "Dengan menambahkan perangkat kami di rumah Anda, Anda dapat memantau dan menikmati banyak manfaat dari pemasangan Sistem Pembangkit Listrik Tenaga Surya (PLTS) dengan produk kami.",
        frendly: "Ramah",
        simple: "Praktis",
        insight: "Penuh Wawasan",
        trusted: "Terpercaya",
        renewed: "memperbaharui",
        access: "akses dimanapun",
        titleHowToUse: "Cara Menggunakan SolarAccess Residencial",
      },
      commercial: {
        titleCommercial: "SolarAccess - Komersial & Industri",
        aboutCommercial:
          "Portal Online SolarAccess memiliki fitur yang menyediakan kenyamanan bagi pelanggan. Pengembangan dengan inovasi terus dilakukan agar pelanggan dapat merasakan manfaat dan kenyamanan. Indikator kinerja untuk PLTS disediakan secara otomatis dan luas, laporan yang dapat dikonfigurasi. SolarAccess adalah solusi yang tepat untuk pemantauan dan pengelolaan operasi PLTS untuk sistem tenaga surya skala industri.",
        titleMonitoring: "Pemantauan",
        contentMonitoring:
          "Antarmuka Pengguna Grafis dengan visual yang interaktif dan mudah dipahami untuk memantau kinerja sistem PLTS Anda.",
        contentMonitoring2:
          "Dashboard Utama menyediakan gambaran menyeluruh tentang kondisi saat ini dari PLTS.",
        contentMonitoring3:
          "Ringkasan terhadap parameter utama yang beroperasi PLTS.",
        contentMonitoring4:
          "Mengetahui kondisi cuaca saat ini dari setiap situs.",
        contentMonitoring5:
          "Grafik Energi Surya yang dapat dilihat secara harian, bulanan, dan tahunan.",
        contentMonitoring6:
          "Perbandingan antara energi yang dihasilkan oleh Energi Surya Fotovoltaik dan energi dari Grid ditunjukkan dalam grafik dan persentase saat ini.",
        titleOperation: "Managemen Operasi",
        contentOperation: "Akses ke semua data pada komponen inti PLTS",
        contentOperation1: "Manajemen Situs PLTS yang terhubung ke akun Anda",
        contentOperation2:
          "Manajemen Pengguna untuk mengelola setiap akun yang terhubung ke situs PLTS.",
        titleReporting: "Laporan",
        contentReporting1:
          "Laporan Individu tentang Operasi Situs selama Satu Bulan",
        contentReporting2: "Rasio Kinerja Generasi Data terhadap Operasi PLTS",
        contentReporting3:
          "Perbandingan Nilai Ideal Sistem PV dengan Kondisi Aktual.",
        contentReporting4: "Ekspor data mentah dan utama dalam format CSV",
        titleAlarm: "Peringatan",
        contentAlarm: "berita terbaru tentang kejadian operasi PLTS",
        contentAlarm2:
          "Penjelasan tentang lokasi peralatan dan waktu kejadian alarm",
        contentAlarm3:
          "Akses riwayat semua Alarm yang telah terjadi pada lokasi PLTS",
        titleBenefit: "Keuntungan",
        contentBenefit:
          "Mengenal operasi energi surya terbaru kapanpun dan dimanapun.",
        contentBenefit2: "Akses ke data keseluruhan dan analisis operasi PLTS",
        contentBenefit3:
          "Menyimpan waktu dalam mengidentifikasi masalah dalam operasi pembangkit listrik untuk memaksimalkan produksi energi PLTS.",
        contentBenefit4:
          "Kami bergantung pada akses data yang mudah ke sistem kami.",
      },
      termConditional: {
        titleTerm: "Syarat & Ketentuan",
        titleTermSolarAccess:
          "Dengan Sistem Manajemen PLTS SolarAccess (“SolarAccess”) untuk perangkat IOS dan Android berdasarkan ketentuan penggunaan berikut, PT. ATW Solar Residensial (“ATW”) menyediakan layanan dan data (secara kolektif “layanan”) misalnya dalam kaitannya dengan pengumpulan, manajemen, dan penampilan data perangkat, system, dan tanaman energi (“sistem”) melalui App Store (IOS) dan Google Play (Android). Prasyaratnya adalah bahwa pengguna adalah pemilik sistem PLTS yang menggunakan produk ATW.",
        li1: "Untuk menggunakan SolarAccess untuk mengelola dan memantau perangkat PLTS yang terhubung, pengguna harus memberi tahu administrator ATW untuk mendapatkan akun dan kata sandi setelah menyelesaikan instalasi sensor. Pengguna harus menjaga kata sandi agar tidak terungkap kepada pihak ketiga yang tidak berwenang.",
        titleHowToUse: "Cara Menggunakan",
        li2: "Untuk menggunakan aplikasi ini, diperlukan untuk mengunduh dan menginstal aplikasi tersebut dari App Store. Pengguna harus memiliki akun di App Store. Dengan mengunduh aplikasi dari App Store, pengguna akan menerima lisensi pribadi, sederhana, tidak dapat dialihkan, yang terbatas pada masa berlaku kontrak pengguna, untuk menginstal aplikasi di perangkat seluler yang terkait dengan akun App Store.",
        li3: "Untuk melepas pendaftaran sebuah akun, hubungi administrator SolarAccess atau hubungi layanan hotline. Setelah masuk ke sistem menggunakan akun, seorang pengguna dapat meminta untuk membuat subakun (termasuk akun co-owner tingkat rendah) dan mengkonfigurasi kata sandi awal untuk subakun. Pengguna dapat menentukan apakah harus mengubah kata sandi pada saat login pertama subakun. Untuk rincian tentang cara membuat dan melepas pendaftaran subakun, hubungi administrator SolarAccess yang sesuai.",
        li4: "Koneksi data mobile diperlukan untuk menggunakan SolarAccess. ATW dengan tegas menunjukkan bahwa, tergantung pada perilaku penggunaan, aplikasi tersebut kadang-kadang membutuhkan volume data yang tinggi dan pengguna mungkin akan menimbulkan biaya yang harus dibayarkan kepada operator koneksi data mobile. Biaya-biaya tersebut harus ditanggung oleh pengguna.",
        li5: "Jika ATW mengalami kerugian karena pengguna gagal mendapatkan persetujuan eksplisit dari pemilik perangkat atau menggunakan perangkat di luar batasan, pengguna setuju dan berjanji untuk menanggung semua kerugian.",
        titleUseLimitations: "Batasan Penggunaan",
        li6: "Akses Solar yang disediakan untuk digunakan telah dikembangkan oleh ATW dan dilindungi oleh hukum hak cipta. ATW memiliki semua hak penggunaan dan distribusi. Pengguna hanya dapat menggunakan dan mengeksploitasi informasi dan hasil layanan daring ini dalam kerangka Ketentuan Penggunaan ini. Pengguna hanya dapat memproses informasi ini untuk penggunaan pribadi. Pengguna tidak diperbolehkan menambahkan elemen aplikasi atau mengubah, menghapus atau memodifikasi elemen SolarAccess dalam bentuk lain tanpa persetujuan tertulis sebelumnya dari ATW. Khususnya, pengguna tidak diperbolehkan menyalin, mengekstrak atau menggunakan elemen grafis atau mencoba dekompilasi kode sumber SolarAccess.",
        titleUser: "Konten Milik pengguna",
        contentUser:
          "Pengguna tidak berhak mengirimkan konten yang diajukan, ataupun diperbolehkan menyimpan konten secara permanen di SolarAccess. ATW tidak bertanggung jawab atas konten, ketepatan, atau bentuk informasi yang diposting. Selain itu, pengguna bertanggung jawab untuk melindungi dan menghindari pelanggaran hak-hak pihak ketiga, khususnya hak merek dagang, hak cipta, dan hak-hak pribadi. Pempostingan konten yang berdasarkan kekerasan, pornografi atau diskriminatif, atau konten lainnya yang ilegal atau menyinggung rasa hormat umum dilarang.",
        titleReference: "Referensi & Tautan",
        contentReference:
          "Informasi yang diposting di Aplikasi ini mungkin termasuk tautan atau referensi ke situs web pihak ketiga yang diperlihatkan secara tepat. Pemasok yang membuat konten ini tersedia memiliki tanggung jawab sepenuhnya terhadapnya. ATW hanya menyediakan akses ke konten ini. ATW tidak memiliki pengaruh terhadap penampilan saat ini, konten, atau kepemilikan situs yang terkait/direferensikan. Oleh karena itu, ATW dengan tegas menjauhkan diri dari semua konten situs yang terkait/direferensikan. Orang yang menyediakan konten yang ilegal, tidak akurat, atau tidak lengkap bertanggung jawab atas segala kerusakan, terutama yang disebabkan",
        titleDisclamer: "Penolakan",
        contentDisclamer1:
          "Data teknis yang digunakan oleh sensor ATW terus diperiksa dan, jika diperlukan, disesuaikan. Namun, cacat dan kesalahan masih dapat terjadi. Kami tidak bertanggung jawab atas kelengkapan dan / atau kebenaran data (nilai-nilai tersebut tidak merepresentasikan fitur yang dijamin). Huawei tidak akan menanggung tanggung jawab atas keakuratan, kebenaran, kelengkapan, atau kualitas informasi, dokumen, hasil yang ditunjukkan, dan informasi pendapatan.",
        contentDisclamer2:
          "ATW tidak bertanggung jawab atas kesalahan atau kelalaian dalam informasi yang telah ditentukan dimana referensi yang dibuat atau yang terkait. Semua tuntutan hukum terhadap ATW yang berkaitan dengan kerusakan materi atau tidak materi, yang disebabkan oleh penggunaan atau tidak penggunaan informasi yang disediakan atau karena penggunaan informasi yang salah atau tidak lengkap, dikecualikan jika tidak ada keculasan bersifat sengaja atau tidak bertanggung jawab secara berat oleh ATW.",
        contentDisclamer3:
          "Khususnya, ATW tidak menerima tanggung jawab apapun untuk cacat dan kerusakan akibatnya, untuk perhitungan laba atau konsumsi diri yang salah, untuk kerugian laba, untuk kerugian penggunaan, untuk kerusakan perangkat keras yang digunakan, untuk hilangnya data, atau untuk gangguan operasi atau kerusakan lainnya. Tanggung jawab atas kehilangan data dibatasi pada biaya khas untuk memulihkan data yang akan berlaku ketika membuat cadangan berkala yang sesuai dengan risiko, kecuali ada tindakan sengaja atau kelalaian yang berat dari ATW.",
        contentDisclamer4:
          "Ini adalah tanggung jawab tunggal pengguna untuk memeriksa konten hasil yang diperoleh dari penggunaan SolarAccess dan kecocokannya untuk tujuan penggunaan tertentu. ATW secara tegas menyatakan bahwa informasi pembayaran pengembalian, tagihan listrik, dan penggunaan energi yang diposting di SolarAccess hanya untuk tujuan pengguna dan, khususnya, tidak dimaksudkan sebagai dasar penyelesaian/pembayaran tagihan atau untuk menghasilkan bukti tuntutan mengenai penghematan energi, penggunaan energi, dan pengiriman energi ke operator jaringan/grid.",
        contentDisclamer5: `Pengguna tidak memiliki klaim atas ketersediaan SolarAccess atau 100% ketersediaan data. Semua informasi, konten, bahan, dan layanan yang disediakan kepada Anda melalui SolarAcess disediakan oleh ATW dengan basis "SEPERTI APA ADANYA", kecuali jika ditentukan lain dalam perjanjian.`,
        contentDisclamer6:
          "ATW tidak memberikan pernyataan atau jaminan apa pun, tersurat atau tersirat, sehubungan dengan operasi SolarAccess atau informasi, konten, materi, produk (termasuk perangkat lunak apa pun) atau layanan yang disertakan atau disediakan kepada Anda melalui SolarAccess, kecuali jika dinyatakan lain dalam bentuk tertulis. Anda secara tegas setuju bahwa penggunaan SolarAccess adalah atas risiko Anda sendiri. ATW menyangkal semua jaminan, tersurat atau tersirat, termasuk namun tidak terbatas pada jaminan kelayakan untuk tujuan tertentu dan kelayakan untuk diperdagangkan, sepenuhnya sesuai dengan hukum yang berlaku.",
        titleQuality: "Perubahan pada Konten dan Kualitas Aplikasi",
        contentQuality:
          "ATW berhak untuk mengubah, menambahkan atau menghapus desain dan konten SolarAccess atau menghentikan publikasi layanan secara sementara atau permanen. Pengguna tidak berhak mendapatkan pemeliharaan atau penyediaan skala atau fungsi tertentu dari SolarAccess",
        contentQuality1:
          "ATW mungkin dari waktu ke waktu akan mengeluarkan versi baru dari Ketentuan ini. Versi baru menjadi sah dan akan menggantikan versi sebelumnya jika Anda tidak menolak versi baru tersebut dalam waktu 3 bulan setelah menerima versi baru, dan jika ATW telah memberi tahu Anda tentang hak Anda untuk menolak dalam waktu 2 bulan. Pemberitahuan ATW harus berisi instruksi eksplisit bahwa ketidakmampuan pengguna untuk menolak versi baru akan dianggap sebagai persetujuannya terhadap versi baru yang mengikat. Jika pengguna menolak versi baru, Ketentuan Penggunaan akan berakhir dengan efek segera, dan pengguna harus segera menahan diri dari menggunakan SolarAccess lagi.",
        contentQuality2:
          "ATW berupaya untuk mempertahankan aplikasi dalam kondisi bebas cacat sesuai dengan teknologi terkini setiap saat. Namun, ATW tidak dapat menjamin bahwa aplikasi tersebut bebas dari kesalahan.",
        titleIndustrial: "Hak Atas Properti Industri dan Hak Cipta",
        contentIndustrial:
          "Konten dari aplikasi dan situs web dan layanan yang berafiliasi dengan ATW adalah properti eksklusif / properti hukum eksklusif dari ATW dan, apabila relevant, pemberi lisensi masing-masing. ATW memiliki semua hak penggunaan dan distribusi. Konten dilindungi oleh hukum nasional dan international, terutama hak cipta. Distribusi, reproduksi, transformasi, pengolahan, penggunaan atau pelanggaran lainnya atas hak kekayaan industri dan hak cipta ATW akan menjadi objek tindakan hukum sipil dan / atau pidana.",
        titleAplicable: "Hukum yang berlaku",
        contentAplicable:
          "Selain penyesuaian aturan (konvensi) untuk penjualan barang, hak dan kewajiban pengguna dan penyedia layanan dalam Syarat Penggunaan ini akan disesuaikan dengan hukum entitas lokal. Tidak ada syarat, bagaimanapun, dapat merusak hak hukum Anda sebagai warga negara suatu tempat.",
        titleSeverAbility: "Klausul Pengasingan",
        contentSeverablility:
          "Apabila bagian atau pembuatan individual dari Ketentuan Penggunaan ini tidak lagi, atau tidak sepenuhnya sesuai dengan hukum yang berlaku, hal ini tidak akan memiliki efek pada bagian-bagian lain dari isi atau validitasnya. Ketentuan yang tidak efektif akan diganti dengan aturan yang akan ditentukan melalui interpretasi, yang mendekati dengan aturan yang dimaksud secara keuangan dan sebenarnya dengan cara yang sah secara hukum.",
      },
      policyPrivacy: {
        titleBar :"SolarAccess - Kebijakan Privasi",
        titlePolicy: "KEBIJAKAN PRIVASI",
        contentPolicy: `Privasi Anda penting bagi kami. Ini adalah kebijakan PT ATW Solar Indonesia untuk menghormati privasi Anda dan mematuhi hukum dan peraturan yang berlaku tentang informasi pribadi apa pun yang mungkin kami kumpulkan tentang Anda, termasuk di situs web kami,`,
        contentPolicy2: `, dan situs lain yang kami miliki dan operasikan.
        Kebijakan ini berlaku mulai tanggal 9 Desember 2021 dan terakhir diperbarui pada tanggal 9 Desember 2021.`,
        titleInformation: "Kami Mengumpulkan Informasi",
        contentInformation: `Informasi yang kami kumpulkan termasuk informasi yang Anda sengaja dan aktif berikan kepada kami saat menggunakan atau berpartisipasi dalam salah satu layanan atau promosi kami, serta informasi yang secara otomatis dikirim oleh perangkat Anda saat mengakses produk dan layanan kami.`,
        titleLog: "Mencatat Data",
        contentLog: `ketika kamu mengunjungi website kami, server kamu mungkin masuk secara otimatis data standar yang disediakan browser anda. ini dapat mencakup perangkat alamat Internet Protocol (IP) anda, tipe dan versi browser anda, halaman yang anda kunjungi, waktu dan tanggal kunjungan anda, waktu yang di habiskan pada setiap halaman, detail lain tentang kunjungan kamu, dan rincian teknis yang terjadi bersamaan dengan kesalahan mungkin anda temui.
        tolomg memahami bahwa sementara ini informasi mungkin bukan identitas pribadi sendiri, mungkin saja untuk menggabungkan ini dengan data lain kepada identitas pribadi sendiri secara individu   `,
        titlePersonal: "Informasi Pribadi",
        contentPersonal:
          "Kami mungkin akan meminta informasi pribadi yang mungkin meliputi satu atau lebih dari yang berikut:",
        name: "Nama",
        email: "Email",
        phone: "Telepon / Nomor Seluler",
        titleLegalisi: "alasan yang sah untuk diproses data pribadi anda",
        contentLegalisi:
          "Kami hanya mengumpulkan dan menggunakan informasi pribadi Anda ketika kami memiliki alasan yang sah untuk melakukannya. Dalam hal ini, kami hanya mengumpulkan informasi pribadi yang cukup wajar untuk memberikan layanan kami kepada Anda.",
        titleCollection: "Pengumpulan dan penggunaan informasi",
        contentCollection:
          "Kami mungkin akan mengumpulkan informasi pribadi dari Anda ketika Anda melakukan salah satu dari berikut ini di situs web kami:",
        contentCollection1:
          "Gunakan perangkat seluler atau peramban web untuk mengakses konten kami.",
        contentCollection2:
          "Hubungi kami melalui email, media sosial, atau teknologi serupa lainnya.",
        contentCollection3: "Ketika Anda menyebutkan kami di media sosial.",
        contentCollection4:
          "Kami mungkin mengumpulkan, menyimpan, menggunakan, dan membocorkan informasi untuk tujuan berikut, dan informasi pribadi tidak akan diproses lebih lanjut dengan cara yang tidak sesuai dengan tujuan-tujuan tersebut.",
        contentCollection5: "untuk menghubungi dan berkomunikasi dengan anda",
        contentCollection6:
          "Untuk analitik, penelitian pasar, dan pengembangan bisnis, termasuk untuk mengoperasikan dan meningkatkan situs web kami, aplikasi terkait, dan platform media sosial terkait.",
        contentCollection7:
          "Harap waspadai bahwa kami mungkin menggabungkan informasi yang kami kumpulkan tentang Anda dengan informasi umum atau data penelitian yang kami terima dari sumber-sumber terpercaya lainnya.",
        titleSecurity: "Keamanan Informasi Pribadi Anda",
        contentSecurity: `Ketika kita mengumpulkan dan memproses informasi pribadi, dan saat kita menyimpan informasi ini, kita akan melindunginya dengan cara yang sesuai secara komersial untuk mencegah kehilangan dan pencurian, serta akses, pengungkapan, penyalinan, penggunaan, atau modifikasi yang tidak sah.
        Meskipun kami akan melakukan yang terbaik untuk melindungi informasi pribadi yang Anda berikan kepada kami, kami menyarankan bahwa tidak ada metode transmisi elektronik atau penyimpanan yang 100% aman, dan tidak ada yang dapat menjamin keamanan data mutlak. Kami akan mematuhi hukum yang berlaku untuk kami mengenai setiap pelanggaran data.
  Anda bertanggung jawab untuk memilih kata sandi dan kekuatan keamanannya secara keseluruhan, memastikan keamanan informasi Anda dalam batas layanan kami.
        `,
        titleChildren: "Privasi Anak-anak",
        contentChildrend:
          "Kami tidak menyasar salah satu produk atau layanan kami secara langsung kepada anak-anak di bawah usia 13, dan kami tidak sengaja mengumpulkan informasi pribadi tentang anak-anak di bawah usia 13.",
        titleDisclosure: "Pengungkapan Informasi Pribadi kepada Pihak Ketiga",
        contentDisclosure:
          "Kami mungkin akan mengungkapkan informasi pribadi kepada:",
        contentDisclosure1:
          "sebuah induk, anak usaha, atau afiliasi dari perusahaan kami",
        contentDisclosure2:
          "Penyedia layanan pihak ketiga untuk tujuan memungkinkan mereka untuk menyediakan layanan mereka, misalnya, penyedia layanan TI, penyimpanan data, penyedia hosting dan server, pengiklan, atau platform analitik.",
        contentDisclosure3:
          "Karyawan, kontraktor, dan/atau entitas terkait kami.",
        contentDisclosure4:
          "Agen atau mitra bisnis kami yang ada atau potensial.",
        contentDisclosure5:
          "Sponsor atau Promotor dari setiap kompetisi, undian, atau promosi yang kami jalankan.",
        contentDisclosure6:
          "lapangan, peradilan, otoritas pengatur, dan pihak kepolisian, seperti yang diwajibkan oleh hukum, dalam kaitannya dengan proses hukum yang sebenarnya atau yang diharapkan, atau untuk menetapkan, melaksanakan, atau mempertahankan hak hukum kita.",
        contentDisclosure7:
          "Kelompok ketiga, termasuk agen atau subkontraktor yang membantu kami dalam memberikan informasi, produk, layanan, atau pemasaran langsung kepada Anda.",
        contentDisclosure8:
          "Pihak ketiga untuk mengumpulkan dan memproses data",
        titleThird: "Divulgasi Informasi Pribadi ke Pihak Ketiga",
        contentThird:
          "Informasi pribadi yang kami kumpulkan disimpan dan/atau diproses di mana kami atau mitra, afiliasi, dan pemasok pihak ketiga kami menjaga fasilitas. Harap diperhatikan bahwa lokasi tempat kami menyimpan, memproses, atau mentransfer informasi pribadi Anda mungkin tidak memiliki undang-undang perlindungan data yang sama dengan negara di mana Anda memberikan informasi tersebut secara awal. Jika kami mentransfer informasi pribadi Anda kepada pihak ketiga di negara lain: (i) kami akan melakukan transfer tersebut sesuai dengan persyaratan yang berlaku; dan (ii) kami akan melindungi informasi pribadi yang ditransfer sesuai dengan kebijakan privasi ini.",
        titlePersonalInformation:
          "Hak Anda dan Mengontrol Informasi Pribadi Anda",
        contentPersonalInformation: `Kamu selalu memiliki hak untuk menahan informasi pribadi dari kami, dengan pengertian bahwa pengalamanmu di situs web kami dapat terpengaruh. Kami tidak akan membeda-bedakanmu karena menjalankan hak apapun atas informasi pribadimu. Jika kamu memang menyediakan kami dengan informasi pribadi, kamu mengerti bahwa kami akan mengumpulkan, menyimpan, menggunakan, dan mengungkapkannya sesuai dengan kebijakan privasi ini. Kamu tetap memiliki hak untuk meminta detail informasi pribadi yang kami miliki tentangmu.
        Jika kami menerima informasi pribadi tentang Anda dari pihak ketiga, kami akan melindunginya sesuai dengan kebijakan privasi ini. Jika Anda adalah pihak ketiga yang memberikan informasi pribadi tentang orang lain, Anda menyatakan dan menjamin bahwa Anda memiliki persetujuan orang tersebut untuk memberikan informasi pribadi kepada kami.
        Jika Anda sebelumnya telah menyetujui kami untuk menggunakan informasi pribadi Anda untuk tujuan pemasaran langsung, Anda dapat mengubah pikiran Anda kapan saja. Kami akan memberi Anda kemampuan untuk berhenti berlangganan dari basis data email kami atau memilih untuk tidak terlibat dalam komunikasi. Mohon dicatat bahwa kami mungkin perlu meminta informasi khusus dari Anda untuk membantu kami mengkonfirmasi identitas Anda.
        Jika Anda percaya bahwa informasi yang kami miliki tentang Anda tidak akurat, kedaluwarsa, tidak lengkap, tidak relevant, atau menyesatkan, silakan hubungi kami menggunakan rincian yang disediakan dalam kebijakan privasi ini. Kami akan melakukan langkah-langkah wajar untuk memperbaiki informasi yang ditemukan tidak akurat, tidak lengkap, menyesatkan, atau kedaluwarsa.
        Jika Anda percaya bahwa kami telah melanggar hukum perlindungan data yang relevan dan ingin mengajukan keluhan, silakan hubungi kami menggunakan rincian di bawah ini dan berikan kami rincian lengkap tentang pelanggaran yang diduga. Kami akan segera menyelidiki keluhan Anda dan menanggapi Anda, secara tertulis, menetapkan hasil penyelidikan kami dan langkah-langkah yang akan kami ambil untuk menangani keluhan Anda. Anda juga berhak menghubungi badan regulasi atau otoritas perlindungan data berkaitan dengan keluhan Anda.
        `,
        titleCookies: "Penggunaan Cookie",
        contentCookies: `Kami menggunakan "cookie" untuk mengumpulkan informasi tentang Anda dan aktivitas Anda di seluruh situs kami. Cookie adalah potongan data kecil yang disimpan oleh situs web kami di komputer Anda, dan diakses setiap kali Anda mengunjungi, sehingga kami dapat memahami cara Anda menggunakan situs kami. Ini membantu kami memberikan Anda konten berdasarkan preferensi yang telah Anda tetapkan.`,
        titleLimits: "Batas Kebijakan Kami",
        contentLimits:
          "Situs web kami mungkin menaut ke situs eksternal yang tidak dioperasikan oleh kami. Harap diingat bahwa kami tidak memiliki kontrol atas konten dan kebijakan situs tersebut, dan tidak dapat menerima tanggung jawab atau tanggung jawab atas praktik privasi masing-masing.",
        titleChange: "Perubahan dalam Kebijakan kami",
        contentChange: `Pada kebijakan kami, kami dapat mengubah kebijakan privasi kami untuk mencerminkan pembaruan proses bisnis kami, praktik yang diterima saat ini, atau perubahan hukum atau peraturan. Jika kami memutuskan untuk mengubah kebijakan privasi ini, kami akan memposting perubahan di sini di tautan yang sama dengan yang Anda akses kebijakan privasi ini.
        Jika diperlukan oleh hukum, kami akan mendapatkan izin Anda atau memberi Anda kesempatan untuk mendaftar atau keluar, sebagaimana yang berlaku, penggunaan baru informasi pribadi Anda.`,
        contentPrivacyContact: `Untuk pertanyaan atau keprihatinan mengenai privasi Anda, Anda dapat menghubungi kami menggunakan rincian berikut: ATW Solar Marketing Team`,
        titleContact:"Hubungi Kami",
        titleHowLong: "Berapa Lama Kami Menyimpan Informasi Pribadi Anda",
        contentHowLong: `Kami hanya akan menyimpan informasi pribadi Anda selama kami membutuhkannya. Periode waktu ini mungkin bergantung pada apa yang kami gunakan informasi Anda untuk, sesuai dengan kebijakan privasi ini. Jika informasi pribadi Anda tidak lagi diperlukan, kami akan menghapusnya atau membuatnya anonim dengan menghapus semua rincian yang mengidentifikasi Anda. Namun, jika diperlukan, kami dapat menyimpan informasi pribadi Anda untuk memenuhi kewajiban hukum, akuntansi, atau pelaporan atau untuk tujuan arsip di tujuan publik, penelitian ilmiah atau sejarah, atau tujuan statistik.`,
      },
      footer:{
        titleContact:"Kami di sini untuk menjawab setiap pertanyaan atau masalah yang mungkin Anda miliki. Apakah Anda memerlukan bantuan untuk menemukan produk yang tepat, memiliki masalah teknis, atau hanya ingin berbagi umpan balik, kami siap membantu.",
        contentContact:"Hubungi Kami",
        contentFooter:"Pelayanan Kami",
        contentFooterOur1:"Tim Kami",
        contentFooterOur2:"Mitra Kami",
        contentFooterOur3:"Produk & Teknologi Kami",
        contentFooterOur4:"Portofolio",
        contentFooterOur5:"berita & Blog",
        contentFooter2:"Sosial Media Kami"
      }
    };
  } else {
    return globalHeader;
  }
};

export default rootHeader;
