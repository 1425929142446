
import {BrowserRouter as Router, Routes,Route} from 'react-router-dom'
import { Fragment } from 'react';
import './App.css';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Landing from './pages/landing';
import Residential from './pages/Residential';
import Commercialandindustrial from './pages/Commercialandindustrial';
import Termandconditional from './pages/Termandconditional';
import Policyandprivacy from './pages/Policyandprivacy';
import Contact from './pages/Contact';



function App() {

  return (
   <>
  <Router>
    <Fragment>
    <Header/>
    <Routes>
      <Route path='/' element={<Landing/>} />
      <Route path='/residential' element={<Residential/>} />
      <Route path='/commercial' element={<Commercialandindustrial/>} />
      <Route path='/term-and-condition' element={<Termandconditional/>} />
      <Route path='/privacy-policy' element={<Policyandprivacy/>} />
      <Route path='/contact' element={<Contact/>} />
    </Routes>
  <Footer/>
    </Fragment>
  </Router>
   </>
  );
}

export default App;
