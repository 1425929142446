import { React, useEffect } from "react";
import Navbar from "../components/Navbar";
import imgEnergiIndo from "../assets/img/img tutorial redential/Picture1_indo.png"
import imgEnergiTextIndo from "../assets/img/img tutorial redential/Picture_textindo.png"
import AOS from "aos";
import "aos/dist/aos.css";
import "../assets/css/residential.css";
import { connect } from "react-redux";

function Residential(props) {
  useEffect(() => {
    document.title = "SolarAccess - Residential";
    AOS.init({
      duration: 1000, // values from 0 to 3000, with step 50ms
    });
  }, []);
  return (
    <div>
      <Navbar title={props.solarAccess} />
      <main id="main">
        <section>
          <div className="container text-center">
            <img
              src="assets/img/img tutorial redential/picture10.png"
              className="img-fluid img-about"
              data-aos="fade-up"
              data-aos-delay={100}
              alt="true"
            />
            <img
              src="assets/img/img tutorial redential/gallery-3 (1).png"
              className="img-fluid img-about"
              data-aos="fade-up"
              data-aos-delay={200}
              alt="true"
            />
            <div className="col-md-8 text-about">
              <p className="text-about-residential" data-aos="fade-up">
                {props.aboutResindetial}
              </p>
              <div className="row" data-aos="fade-up">
                <div
                  className="col-md-6 button-m-link"
                  style={{ textAlign: "end" }}
                >
                  <a
                    href="https://play.google.com/store/apps/details?id=com.atwsolar.solaraccess"
                    target={"_blank"}
                    className="download-btn"
                    rel="noreferrer"
                  >
                    <i className="fab fa-google-play" /> Google Play
                  </a>
                </div>
                <div
                  className="col-md-6 button-m-link"
                  style={{ textAlign: "initial" }}
                >
                  <a
                    href="https://apps.apple.com/app/solaraccess-by-atw-solar/id1643656410"
                    target={"_blank"}
                    className="download-btn"
                    rel="noreferrer"
                  >
                    <i className="fab fa-apple" /> App Store
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center"></div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row smart-energy">
              <div className="col-md-6 text-center" data-aos="fade-right">
                <img
                  src="assets/img/img tutorial redential/Picture19.png"
                  className="img-fluid img-meter"
                  alt="true"
                />
              </div>
              <div className="col-md-6 " data-aos="fade-left">
                <h1>{props.titleSmartEnergi}</h1>
                <p className="text-energi">{props.contentSmartEnergi}</p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container text-center">
            <h1 className="text-center" data-aos="fade-up" data-aos-delay={100}>
              {props.titleEnergiHistory}
            </h1>
            {/* <img src="assets/img/img tutorial redential/Picture5.png" data-aos="fade-up" data-aos-delay="200" class="img-fluid img-how-to text-center"  alt="true"> */}
            <div className="energi-pc">
              <img
                src="assets/img/img tutorial redential/Picture5.png"
                data-aos="fade-up"
                data-aos-delay={200}
                className="img-fluid img-how-to text-center"
                alt="true"
                style={{display:`${props.energiDisplay}`,margin:"auto"}}
              />

                  <img
                  src={imgEnergiIndo}
                  className="img-fluid img-how-to text-center"
                  alt="true"
                  style={{display:`${props.energiDisplayIndo}`,margin:"auto"}}
                />
            </div>
            <div className="energi-mobile">
              <img
                src="assets/img/energisistem.png"
                data-aos="fade-up"
                data-aos-delay={200}
                className="img-fluid img-how-to text-center"
                style={{ width: "70%" }}
                alt="true"
              />
              <img
                src="assets/img/text.png"
                data-aos="fade-up"
                data-aos-delay={200}
                className="img-fluid img-how-to text-center"
                style={{ width: "100%",display:`${props.energiDisplay}` }}
                alt="true"
              />
              <img
                src={imgEnergiTextIndo}
                data-aos="fade-up"
                data-aos-delay={200}
                className="img-fluid img-how-to text-center"
                style={{ width: "100%",display:`${props.energiDisplayIndo}` }}
                alt="true"
              />
            </div>
          </div>
        </section>
        <section className="slide-section-mobile">
          <div className="container">
            <div className="row future1">
              <div className="col-md-7">
                <div className="realtime">
                  <div
                    className="row mt-3 future1-text"
                    data-aos="fade-down"
                    data-aos-delay={100}
                  >
                    <div className="col-md-2">
                      <i className="fas fa-stopwatch future1-icon" />
                    </div>
                    <div className="col-md-10">
                      <h4>{props.titleRealMonitoring}</h4>
                      <p>{props.titleEnergiHistory}</p>
                    </div>
                  </div>
                  <div
                    className="row mt-3 future1-text"
                    data-aos="fade-down"
                    data-aos-delay={200}
                  >
                    <div className="col-md-2">
                      <i className="fas fa-chart-bar future1-icon" />
                    </div>
                    <div className="col-md-10">
                      <h4>{props.titleRealMonitoring}</h4>
                      <p>{props.contentRealMonitoring}</p>
                    </div>
                  </div>
                  <div
                    className="row mt-3 future1-text"
                    data-aos="fade-down"
                    data-aos-delay={300}
                  >
                    <div className="col-md-2">
                      <i className="fas fa-sun future1-icon" />
                    </div>
                    <div className="col-md-10">
                      <h4>{props.titleEnergiUsage}</h4>
                      <p>{props.contentEnergiUsage}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 img-future1">
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to={0}
                      className="active"
                    />
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to={1}
                    />
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to={2}
                    />
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        className="img-fluid img-asset-1"
                        src="assets/img/img tutorial redential/picturr1.jpg"
                        alt="First slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="img-fluid img-asset-1"
                        src="assets/img/img tutorial redential/picturr2.jpg"
                        alt="Second slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="img-fluid img-asset-1"
                        src="assets/img/img tutorial redential/picturr3.jpg"
                        alt="Third slide"
                      />
                    </div>
                  </div>
                  <a
                    className="carousel-control-prev"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-5 img-future1">
                <div
                  id="carouselExampleIndicators2"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to={0}
                      className="active"
                    />
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to={1}
                    />
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to={2}
                    />
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        className="img-fluid img-asset-1"
                        src="assets/img/img tutorial redential/picturr4.jpg"
                        alt="First slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="img-fluid img-asset-1"
                        src="assets/img/img tutorial redential/picturr6.jpg"
                        alt="Second slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="img-fluid img-asset-1"
                        src="assets/img/img tutorial redential/picturr5.jpg"
                        alt="Third slide"
                      />
                    </div>
                  </div>
                  <a
                    className="carousel-control-prev"
                    href="#carouselExampleIndicators2"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#carouselExampleIndicators2"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
              <div className="col-md-7">
                <div className="realtime">
                  <div
                    className="row mt-3 future1-text"
                    data-aos="fade-down"
                    data-aos-delay={100}
                  >
                    <div className="col-md-2">
                      <i className="fas fa-coins future1-icon" />
                    </div>
                    <div className="col-md-10">
                      <h4>{props.titleForecast}</h4>
                      <p>{props.contentForecast}</p>
                    </div>
                  </div>
                  <div
                    className="row mt-3 future1-text"
                    data-aos="fade-down"
                    data-aos-delay={400}
                  >
                    <div className="col-md-2">
                      <i className="fas fa-house future1-icon" />
                    </div>
                    <div className="col-md-10">
                      <h4>{props.shareFamily}</h4>
                      <p>{props.contentshareFamily}</p>
                    </div>
                  </div>
                  <div
                    className="row mt-3 future1-text"
                    data-aos="fade-down"
                    data-aos-delay={300}
                  >
                    <div className="col-md-2">
                      <i className="fas fa-shopping-cart future1-icon" />
                    </div>
                    <div className="col-md-10">
                      <h4>{props.titleProduct}</h4>
                      <p>{props.contentProduct}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row benefit-m">
              <div className="col-md-6 col-benefit">
                <h1 data-aos="fade-down" data-aos-delay={100}>
                  {props.titleBenefitValue}
                </h1>
                <p
                  className="text-benefit pt-5"
                  data-aos="fade-down"
                  data-aos-delay={100}
                >
                  {props.contentBenefitValue}
                </p>
                <div className="row pt-3" data-aos="fade-down">
                  <div className="col-md-6 icon-benefit-m">
                    <div className="d-block pb-4">
                      <i className="far fa-star" style={{ paddingRight: 5 }} />{" "}
                      {props.frendly}
                    </div>
                    <div className="d-block pb-4">
                      <i className="fas fa-check" style={{ paddingRight: 5 }} />{" "}
                      {props.simple}
                    </div>
                    <div className="d-block pb-4">
                      <i
                        className="fas fa-chart-line"
                        style={{ paddingRight: 5 }}
                      />{" "}
                      {props.insight}
                    </div>
                  </div>
                  <div className="col-md-6 icon-benefit-m">
                    <div className="d-block pb-4">
                      <i className="far fa-star" style={{ paddingRight: 5 }} />{" "}
                      {props.trusted}
                    </div>
                    <div className="d-block pb-4">
                      <i
                        className="fas fa-handshake"
                        style={{ paddingRight: 5 }}
                      />
                      {props.renewed}
                    </div>
                    <div className="d-block pb-4">
                      <i
                        className="fas fa-universal-access"
                        style={{ paddingRight: 5 }}
                      />{" "}
                      {props.access}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 img-mobile"
                data-aos="fade-down"
                data-aos-delay={200}
              >
                <img
                  src="assets/img/galery7.jpg"
                  className="img-fluid img-benefit"
                  alt="true"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <h1 className="text-center" data-aos="fade-up" data-aos-delay={100}>
              {props.titleHowToUse}
            </h1>
            <div className="row">
              <div
                className="col-md-3 residential-tutorial"
                data-aos="flip-left"
                data-aos-delay={100}
              >
                <img
                  src="assets/img/img tutorial redential/Picture1.png"
                  className="img-fluid img-how-to"
                  alt="true"
                />
              </div>
              <div
                className="col-md-3 residential-tutorial"
                data-aos="flip-left"
                data-aos-delay={300}
              >
                <img
                  src="assets/img/img tutorial redential/Picture2.png"
                  className="img-fluid img-how-to"
                  alt="true"
                />
              </div>
              <div
                className="col-md-3 residential-tutorial"
                data-aos="flip-left"
                data-aos-delay={600}
              >
                <img
                  src="assets/img/img tutorial redential/Picture3.png"
                  className="img-fluid img-how-to"
                  alt="true"
                />
              </div>
              <div
                className="col-md-3 residential-tutorial "
                data-aos="flip-left"
                data-aos-delay={900}
              >
                <img
                  src="assets/img/img tutorial redential/Picture4.png"
                  className="img-fluid img-how-to"
                  alt="true"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container"></div>
        </section>
      </main>
      {/* End #main */}
    </div>
  );
}

const mapStateHeaderProps = (state) => {
  return {
    solarAccess: state.residential.solarAccess,
energiDisplay:state.residential.energiDisplay,
    energiDisplayIndo:state.residential.energiDisplayIndo,
    aboutResindetial: state.residential.aboutResindetial,
    titleSmartEnergi: state.residential.titleSmartEnergi,
    contentSmartEnergi: state.residential.contentSmartEnergi,
    titleEnergiHistory: state.residential.titleEnergiHistory,
    titleRealMonitoring: state.residential.titleRealMonitoring,
    contentRealMonitoring: state.residential.contentRealMonitoring,
    titleEnergiUsage: state.residential.titleEnergiUsage,
    contentEnergiUsage: state.residential.contentEnergiUsage,
    titleSystemBenefit: state.residential.titleSystemBenefit,
    contentSystemBenefit: state.residential.contentSystemBenefit,
    titleForecast: state.residential.titleForecast,
    contentForecast: state.residential.contentForecast,
    shareFamily: state.residential.shareFamily,
    contentshareFamily: state.residential.contentshareFamily,
    titleProduct: state.residential.titleProduct,
    contentProduct: state.residential.contentProduct,
    titleBenefitValue: state.residential.titleBenefitValue,
    contentBenefitValue: state.residential.contentBenefitValue,
    frendly: state.residential.frendly,
    simple: state.residential.simple,
    insight: state.residential.insight,
    trusted: state.residential.trusted,
    renewed: state.residential.renewed,
    access: state.residential.access,
    titleHowToUse: state.residential.titleHowToUse,
  };
};

export default connect(mapStateHeaderProps)(Residential);
