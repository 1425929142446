import { React, useEffect } from "react";
import Navbar from "../components/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import "../assets/css/residential.css";
import "../assets/css/commercial.css";
import { connect } from "react-redux";

function Commercialandindustrial(props) {
  useEffect(() => {
    document.title = "SolarAccess - Commercial & Industrial";
    AOS.init({
      duration: 1000, // values from 0 to 3000, with step 50ms
    });
  }, []);
  return (
    <div>
      <Navbar title={props.titleCommercial} />
      <main id="main">
        <section>
          <div className="container">
            <div className="row about-commercial" data-aos="fade-up">
              <div className="col-md-7">
                <p className="text-about">{props.aboutCommercial}</p>
              </div>
              <div className="col-md-5 col-commercial-img">
                <img
                  src="assets/img/img tutorial redential/picture14.png"
                  className="img-fluid img-description"
                  alt="true"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-5" data-aos="fade-up">
                <img
                  src="assets/img/img tutorial redential/picture15.png"
                  className="img-fluid"
                  alt="true"
                />
              </div>
              <div className="col-md-7 feuture1">
                <h1 data-aos="fade-up">{props.titleMonitoring}</h1>
                <div className="text-fueture row" data-aos="fade-up">
                  <div className="col-md-1 m-feuture1-icon">
                    <i className="fa-solid fa-display icon-fueture" />
                  </div>
                  <div className="col-md-10 m-feuture1">
                    {props.contentMonitoring}
                  </div>
                </div>
                <div className="text-fueture row" data-aos="fade-up">
                  <div className="col-md-1 m-feuture1-icon">
                    <i className="fa-solid fa-gauge icon-fueture" />
                  </div>
                  <div className="col-md-10 m-feuture1">
                    {props.contentMonitoring2}
                  </div>
                </div>
                <div className="text-fueture row" data-aos="fade-up">
                  <div className="col-md-1 m-feuture1-icon">
                    <i className="fa-solid fa-filter icon-fueture" />
                  </div>
                  <div className="col-md-10 m-feuture1">
                    {props.contentMonitoring3}
                  </div>
                </div>
                <div className="text-fueture row" data-aos="fade-up">
                  <div className="col-md-1 m-feuture1-icon">
                    <i className="fa-solid fa-cloud icon-fueture" />
                  </div>
                  <div className="col-md-10 m-feuture1">
                    {props.contentMonitoring4}
                  </div>
                </div>
                <div className="text-fueture row" data-aos="fade-up">
                  <div className="col-md-1 m-feuture1-icon">
                    <i className="fa-solid fa-chart-simple icon-fueture" />
                  </div>
                  <div className="col-md-10 m-feuture1">
                    {props.contentMonitoring5}
                  </div>
                </div>
                <div className="text-fueture row" data-aos="fade-up">
                  <div className="col-md-1 m-feuture1-icon">
                    <i className="fa-solid fa-bolt icon-fueture" />
                  </div>
                  <div className="col-md-10 m-feuture1">
                    {props.contentMonitoring6}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="conatiner">
            <div className="container">
              <div className="row feuture2-m">
                <div className="col-md-6 feuture1">
                  <h1 data-aos="fade-up">{props.titleOperation}</h1>
                  <div className="row text-fueture2" data-aos="fade-up">
                    <div className="col-md-11 m-feuture1">
                      {props.contentOperation}
                    </div>
                    <div className="col-md-1 m-feuture1-icon">
                      <i className="fa-solid fa-universal-access icon-fueture" />
                    </div>
                  </div>
                  <div className="row text-fueture2" data-aos="fade-up">
                    <div className="col-md-11 m-feuture1">
                      {props.contentOperation1}
                    </div>
                    <div className="col-md-1 m-feuture1-icon">
                      <i className="fa-solid fa-users icon-fueture" />
                    </div>
                  </div>
                  <div className="row text-fueture2" data-aos="fade-up">
                    <div className="col-md-11 m-feuture1">
                      {props.contentOperation2}
                    </div>
                    <div className="col-md-1 m-feuture1-icon">
                      <i className="fa-solid fa-sitemap icon-fueture" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 img-feuture2-m" data-aos="fade-up">
                  <img
                    src="assets/img/img tutorial redential/picture16.png"
                    className="img-fluid img-fueture2"
                    alt="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row report-mobile">
              <div className="col-md-4 img-feuture2-m" data-aos="fade-up">
                <img
                  src="assets/img/img tutorial redential/picture17.png"
                  className="img-fluid img-fueture2"
                  alt="true"
                />
              </div>
              <div className="col-md-8 feuture2">
                <h1 data-aos="fade-up">{props.titleReporting}</h1>
                <div className="row  fueture3-m" data-aos="fade-up">
                  <div className="col-md-1 m-feuture1-icon">
                    <i className="fa-solid fa-book icon-fueture" />
                  </div>
                  <div className="col-md-11 m-feuture1">
                    {props.contentReporting1}
                  </div>
                </div>
                <div className="row fueture3-m" data-aos="fade-up">
                  <div className="col-md-1 m-feuture1-icon">
                    <i className="fa-solid fa-chart-simple icon-fueture" />
                  </div>
                  <div className="col-md-11 m-feuture1">
                    {props.contentReporting2}
                  </div>
                </div>
                <div className="row fueture3-m" data-aos="fade-up">
                  <div className="col-md-1 m-feuture1-icon">
                    <i className="fa-solid fa-magnifying-glass-chart icon-fueture" />
                  </div>
                  <div className="col-md-11 m-feuture1">
                    {props.contentReporting3}
                  </div>
                </div>
                <div className="row fueture3-m" data-aos="fade-up">
                  <div className="col-md-1 m-feuture1-icon">
                    <i className="fa-solid fa-file-export icon-fueture" />
                  </div>
                  <div className="col-md-11 m-feuture1">
                    {props.contentReporting4}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row feuture2-m">
              <div className="col-md-6 feuture2 pt-5">
                <h1 data-aos="fade-up">{props.titleAlarm}</h1>
                <div className="row text-fueture3" data-aos="fade-up">
                  <div className="col-md-11 m-feuture1">
                    {props.contentAlarm}
                  </div>
                  <div className="col-md-1 m-feuture1-icon">
                    <i className="fa-solid fa-bell icon-fueture" />
                  </div>
                </div>
                <div className="row text-fueture3" data-aos="fade-up">
                  <div className="col-md-11 m-feuture1">
                    {props.contentAlarm2}
                  </div>
                  <div className="col-md-1 m-feuture1-icon">
                    <i className="fa-solid fa-clock icon-fueture" />
                  </div>
                </div>
                <div className="row text-fueture3" data-aos="fade-up">
                  <div className="col-md-11 m-feuture1">
                    {props.contentAlarm3}
                  </div>
                  <div className="col-md-1 m-feuture1-icon">
                    <i className="fa-solid fa-history icon-fueture" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 img-feuture2-m" data-aos="fade-up">
                <img
                  src="assets/img/img tutorial redential/picture18.png"
                  className="img-fluid img-fueture2"
                  alt="true"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row report-mobile">
              <div className="col-md-4 img-feuture2-m" data-aos="fade-up">
                <img
                  src="assets/img/galery7.jpg"
                  className="img-fluid img-fueture3"
                  alt="true"
                />
              </div>
              <div className="col-md-8">
                <h1 data-aos="fade-up">{props.titleBenefit}</h1>
                <div className="row text-fueture " data-aos="fade-up">
                  <div className="col-md-1 m-feuture1-icon">
                    <i className="fa-solid fa-check icon-fueture" />
                  </div>
                  <div className="col-md-11 m-feuture1" lang="id">
                    {props.contentBenefit}
                  </div>
                </div>
                <div className="row text-fueture " data-aos="fade-up">
                  <div className="col-md-1 m-feuture1-icon">
                    <i className="fa-solid fa-check icon-fueture" />
                  </div>
                  <div className="col-md-11 m-feuture1">
                    {props.contentBenefit2}
                  </div>
                </div>
                <div className="row text-fueture " data-aos="fade-up">
                  <div className="col-md-1 m-feuture1-icon">
                    <i className="fa-solid fa-check icon-fueture" />
                  </div>
                  <div className="col-md-11 m-feuture1">
                    {props.contentBenefit3}
                  </div>
                </div>
                <div className="row text-fueture " data-aos="fade-up">
                  <div className="col-md-1 m-feuture1-icon">
                    <i className="fa-solid fa-check icon-fueture" />
                  </div>
                  <div className="col-md-11 m-feuture1">
                    {props.contentBenefit4}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* End #main */}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    titleCommercial: state.commercial.titleCommercial,
    aboutCommercial: state.commercial.aboutCommercial,
    titleMonitoring: state.commercial.titleMonitoring,
    contentMonitoring: state.commercial.contentMonitoring,
    contentMonitoring2: state.commercial.contentMonitoring2,
    contentMonitoring3: state.commercial.contentMonitoring3,
    contentMonitoring4: state.commercial.contentMonitoring4,
    contentMonitoring5: state.commercial.contentMonitoring5,
    contentMonitoring6: state.commercial.contentMonitoring6,
    titleOperation: state.commercial.titleOperation,
    contentOperation: state.commercial.contentOperation,
    contentOperation1: state.commercial.contentOperation1,
    contentOperation2: state.commercial.contentOperation2,
    titleReporting: state.commercial.titleReporting,
    contentReporting1: state.commercial.contentReporting1,
    contentReporting2: state.commercial.contentReporting2,
    contentReporting3: state.commercial.contentReporting3,
    contentReporting4: state.commercial.contentReporting4,
    titleAlarm: state.commercial.titleAlarm,
    contentAlarm: state.commercial.contentAlarm,
    contentAlarm2: state.commercial.contentAlarm2,
    contentAlarm3: state.commercial.contentAlarm3,
    titleBenefit: state.commercial.titleBenefit,
    contentBenefit: state.commercial.contentBenefit,
    contentBenefit2: state.commercial.contentBenefit2,
    contentBenefit3: state.commercial.contentBenefit3,
    contentBenefit4: state.commercial.contentBenefit4,
  };
};
export default connect(mapStateToProps)(Commercialandindustrial);
