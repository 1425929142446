import { React, useEffect } from "react";
import Navbar from "../components/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import "../assets/css/term.css";
import { connect } from "react-redux";

function Termandconditional(props) {
  useEffect(() => {
    document.title = "SolarAccess - Term & Conditional";
    AOS.init({
      duration: 1000, // values from 0 to 3000, with step 50ms
    });
  }, []);
  return (
    <div>
      <Navbar title={props.titleTerm} />
      <main id="main">
        <section className="section1">
          <div className="container">
            <div className="text-center pb-5">
              <img
                src="assets/img/solarAccess_logo.png"
                className="img-fluid"
                width="250px"
                alt="true"
              />
            </div>
            <p className="text-center title-term">
              {props.titleTermSolarAccess}
            </p>
          </div>
        </section>
        <section id="faq" className="faq ">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <ul className="faq-list"> 
                <li>
                  <div
                    data-bs-toggle="collapse"
                    className="collapsed question"
                    href="#faq1"
                  >
                    {props.titleHowToUse}{" "}
                    <i className="bi bi-chevron-down icon-show" />
                    <i className="bi bi-chevron-up icon-close" />
                  </div>
                  <div
                    id="faq1"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <ol>
                      <li>{props.li1}</li>
                      <li>{props.li2}</li>
                      <li>{props.li3}</li>
                      <li>{props.li4}</li>
                      <li>{props.li5}</li>
                    </ol>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq2"
                    className="collapsed question"
                  >
                    {props.titleUseLimitations}
                    <i className="bi bi-chevron-down icon-show" />
                    <i className="bi bi-chevron-up icon-close" />
                  </div>
                  <div
                    id="faq2"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>{props.li6}</p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq3"
                    className="collapsed question"
                  >
                    {" "}
                    {props.titleUser}
                    <i className="bi bi-chevron-down icon-show" />
                    <i className="bi bi-chevron-up icon-close" />
                  </div>
                  <div
                    id="faq3"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>{props.contentUser}</p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq4"
                    className="collapsed question"
                  >
                    {" "}
                    {props.titleReference}
                    <i className="bi bi-chevron-down icon-show" />
                    <i className="bi bi-chevron-up icon-close" />
                  </div>
                  <div
                    id="faq4"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>{props.contentReference}</p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq5"
                    className="collapsed question"
                  >
                    {props.titleDisclamer}{" "}
                    <i className="bi bi-chevron-down icon-show" />
                    <i className="bi bi-chevron-up icon-close" />
                  </div>
                  <div
                    id="faq5"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <ol>
                      <li>{props.contentDisclamer1}</li>
                      <li>{props.contentDisclamer2}</li>
                      <li>{props.contentDisclamer3}</li>
                      <li>{props.contentDisclamer4}</li>
                      <li>{props.contentDisclamer5}</li>
                      <li>{props.contentDisclamer6}</li>
                    </ol>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq6"
                    className="collapsed question"
                  >
                    {props.titleQuality}{" "}
                    <i className="bi bi-chevron-down icon-show" />
                    <i className="bi bi-chevron-up icon-close" />
                  </div>
                  <div
                    id="faq6"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <ol>
                      <li>{props.contentQuality}</li>
                      <li>{props.contentQuality1}</li>
                      <li>{props.contentQuality2}</li>
                    </ol>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq7"
                    className="collapsed question"
                  >
                    {props.titleIndustrial}{" "}
                    <i className="bi bi-chevron-down icon-show" />
                    <i className="bi bi-chevron-up icon-close" />
                  </div>
                  <div
                    id="faq7"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>{props.contentIndustrial}</p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq8"
                    className="collapsed question"
                  >
                    {" "}
                    {props.titleAplicable}
                    <i className="bi bi-chevron-down icon-show" />
                    <i className="bi bi-chevron-up icon-close" />
                  </div>
                  <div
                    id="faq8"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>{props.contentAplicable}</p>
                  </div>
                </li>
                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq9"
                    className="collapsed question"
                  >
                    {" "}
                    {props.titleSeverAbility}
                    <i className="bi bi-chevron-down icon-show" />
                    <i className="bi bi-chevron-up icon-close" />
                  </div>
                  <div
                    id="faq9"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>{props.contentSeverablility}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
      {/* End #main */}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    titleTerm: state.termConditional.titleTerm,
    titleTermSolarAccess: state.termConditional.titleTermSolarAccess,
    li1: state.termConditional.li1,
    titleHowToUse: state.termConditional.titleHowToUse,
    li2: state.termConditional.li2,
    li3: state.termConditional.li3,
    li4: state.termConditional.li4,
    li5: state.termConditional.li5,
    titleUseLimitations: state.termConditional.titleUseLimitations,
    li6: state.termConditional.li6,
    titleUser: state.termConditional.titleUser,
    contentUser: state.termConditional.contentUser,
    titleReference: state.termConditional.titleReference,
    contentReference: state.termConditional.contentReference,
    titleDisclamer: state.termConditional.titleDisclamer,
    contentDisclamer1: state.termConditional.contentDisclamer1,
    contentDisclamer2: state.termConditional.contentDisclamer2,
    contentDisclamer3: state.termConditional.contentDisclamer3,
    contentDisclamer4: state.termConditional.contentDisclamer4,
    contentDisclamer5: state.termConditional.contentDisclamer5,
    contentDisclamer6: state.termConditional.contentDisclamer6,
    titleQuality: state.termConditional.titleQuality,
    contentQuality: state.termConditional.contentQuality,
    contentQuality1: state.termConditional.contentQuality1,
    contentQuality2: state.termConditional.contentQuality2,
    titleIndustrial: state.termConditional.titleIndustrial,
    contentIndustrial: state.termConditional.contentIndustrial,
    titleAplicable: state.termConditional.titleAplicable,
    contentAplicable: state.termConditional.contentAplicable,
    titleSeverAbility: state.termConditional.titleSeverAbility,
    contentSeverablility: state.termConditional.contentSeverablility,
  };
};

export default connect(mapStateToProps)(Termandconditional);
