import React from "react";

function Navbar(props) {
  return (
    <div>
      <section id="hero" className="align-items-center">
        <div className="row container navbar-solar-text m-auto">
          <div
            className="col-md-12 text-access"
            data-aos="zoom-out"
            data-aos-delay={100}
          >
            <p className="text-center mt-5 term">{props.title}</p>
          </div>
        </div>
      </section>
      {/* End Hero */}
    </div>
  );
}

export default Navbar;
