import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

function Footer(props) {
  return (
    <div>
      <div>
        {/* ======= Footer ======= */}
        <footer id="footer">
          <div className="footer-newsletter">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <p>{props.titleContact}</p>
                </div>
                <div className="col-md-4">
                  <NavLink to={"contact"} className="btn btn-outline-dark">
                    {props.contentContact}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 footer-contact">
                  <img
                    src="assets/img/solarAccess_logo.png"
                    className="img-fluid img-footer mb-2"
                    alt="true"
                  />
                  <p>
                    Jl. Darmawangsa VI No.31 RT.5/RW.1, Pulo, Kby. Baru <br />
                    Jakarta Selatan, Jakarta <br /> 12160 <br />
                    <strong>Phone:</strong> +62 21 2765 8985
                    <br />
                    <strong>Email:</strong> sales@atw-solar.id
                    <br />
                  </p>
                </div>
                <div className="col-lg-4 col-md-6 footer-links">
                  <h4>{props.contentFooter}</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a href="https://www.atw-solar.id/about/team/">
                        {props.contentFooterOur1}
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a href="https://www.atw-solar.id/about/partner/">
                        {props.contentFooterOur2}
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a href="https://www.atw-solar.id/about/product-technology/">
                        {props.contentFooterOur3}
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a href="https://www.atw-solar.id/portfolios/">
                      {props.contentFooterOur4}
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a href="https://www.atw-solar.id/news-and-blog/">
                        {props.contentFooterOur5}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4  col-md-6 footer-links">
                  <h4>{props.contentFooter2}</h4>
                  <div className="social-links mt-3">
                    <a
                      href="https://web.facebook.com/ATWSolarIndonesia"
                      className="facebook"
                      style={{ backgroundColor: "#1a3360" }}
                    >
                      <i className="bx bxl-facebook" />
                    </a>
                    <a
                      href="https://twitter.com/atwsolar_id"
                      className="twitter"
                      style={{ backgroundColor: "#1a3360" }}
                    >
                      <i className="bx bxl-twitter" />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCHtkfirdtUHCIi5DkRkiisg"
                      className="instagram"
                      style={{ backgroundColor: "#1a3360" }}
                    >
                      <i className="bx bxl-youtube" />
                    </a>
                    <a
                      href="https://id.linkedin.com/company/pt-atw-solar-indonesia"
                      className="instagram"
                      style={{ backgroundColor: "#1a3360" }}
                    >
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a
                      href="https://www.instagram.com/atw_solar/"
                      className="instagram"
                      style={{ backgroundColor: "#1a3360" }}
                    >
                      <i className="bx bxl-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <a
          href="#/"
          className="back-to-top d-flex align-items-center justify-content-center"
        >
          <i className="bi bi-arrow-up-short" />
        </a>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    titleContact: state.footer.titleContact,
    contentContact: state.footer.contentContact,
    contentFooter: state.footer.contentFooter,
    contentFooterOur1: state.footer.contentFooterOur1,
    contentFooterOur2: state.footer.contentFooterOur2,
    contentFooterOur3: state.footer.contentFooterOur3,
    contentFooterOur4: state.footer.contentFooterOur4,
    contentFooterOur5: state.footer.contentFooterOur5,
    contentFooter2: state.footer.contentFooter2,
  };
};

export default connect(mapStateToProps)(Footer);
