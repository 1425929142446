import { React, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import { connect } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import axios from "axios";
function Contact(props) {
  useEffect(() => {
    document.title = "SolarAccess - Contact";
    AOS.init({
      duration: 1000, // values from 0 to 3000, with step 50ms
    });
  }, []);

  const [spinnerLoading, setSpinnerLoading] = useState({
    nameDisplaySpinner: "none",
    nameDisplaySubmit: "block",
  });

  // untuk mendapatkan value data sementara
  const [inputForm, setInputform] = useState({
    firstName: "",
    lastName: "",
    noTlp: "",
    email: "",
    message: "",
  });
  // untuk mendapatkan value inputan firstname
  const onChangeName = (e) => {
    const value = e.target.value;
    setInputform({
      ...inputForm,
      firstName: value,
    });
  };
  // untuk mendapatkan value inputan lastname
  const onChangeLastName = (e) => {
    const value = e.target.value;
    setInputform({
      ...inputForm,
      lastName: value,
    });
  };
  // untuk mendapatkan value inputan nomer telephone
  const onChangeTlp = (e) => {
    const value = e.target.value;
    setInputform({
      ...inputForm,
      noTlp: value,
    });
  };
  // untuk mendapatkan value inputan email
  const onChangeEmail = (e) => {
    const value = e.target.value;
    setInputform({
      ...inputForm,
      email: value,
    });
  };
  // untuk mendapatkan value inputan pesan
  const onChangeMessage = (e) => {
    const value = e.target.value;
    setInputform({
      ...inputForm,
      message: value,
    });
  };

  const sendEmail = () => {
    const mySwal = withReactContent(Swal);
    if (inputForm.firstName === "") {
      mySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "First Name Not Empty",
        // footer: '<a href="">Why do I have this issue?</a>'
      });
    } else if (inputForm.lastName === "") {
      mySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Last Name Not Empty",
        // footer: '<a href="">Why do I have this issue?</a>'
      });
    } else if (inputForm.email === "") {
      mySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Email Not Empty",
        // footer: '<a href="">Why do I have this issue?</a>'
      });
    } else if (inputForm.noTlp === "") {
      mySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Number Phone Not Empty",
        // footer: '<a href="">Why do I have this issue?</a>'
      });
    } else if (inputForm.message === "") {
      mySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Message Not Empty",
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_URL_API}`,
          {
            firstname: inputForm.firstName,
            lastname: inputForm.lastName,
            notlp: inputForm.noTlp,
            email: inputForm.email,
            message: inputForm.message,
          },
          {
            auth: {
              username: process.env.REACT_APP_BAUTH_USERNAME,
              password: process.env.REACT_APP_BAUTH_PASSWORD,
            },
            onUploadProgress: () => {
              setSpinnerLoading({
                nameDisplaySpinner: "block",
                nameDisplaySubmit: "none",
              });
            },
          }
        )
        .then((e) => {
          setSpinnerLoading({
            nameDisplaySpinner: "none",
            nameDisplaySubmit: "block",
          });

          if (e.data.status === "Success") {
            setInputform({
              firstName: "",
              lastName: "",
              message: "",
              noTlp: "",
              email: "",
            });
            mySwal.fire({
              title: <strong>Your Message Was Sent</strong>,
              icon: "success",
            });
          } else {
            mySwal.fire({
              icon: "error",
              title: "Oops...",
              text: "Your Message Was'nt Sent!",
            });
          }
        })
        .catch(function (e) {
          mySwal.fire({
            icon: "error",
            title: "Oops...",
            text: "Your Message Was'nt Sent!",
          });
        });
    }
  };
  return (
    <div>
      <Navbar title={props.contactUS.labelContact} />
      <main id="main" style={{ marginBottom: "20px" }}>
        <section>
          <h1 className="text-center mb-5" data-aos="fade-up">
            {props.contactUS.getInTouch}
          </h1>
          <div className="container">
            <div className="row text-center m-address">
              <div
                className="col-md-4 m-cardaddress"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <i className="fa fa-map-pin" aria-hidden="true" />
                <br />
                <p className="mt-3">
                  Jl. Darmawangsa VI No.31 <br />
                  RT.5/RW.1, Pulo, Kby. Baru <br />
                  Jakarta Selatan, Jakarta 12160
                </p>
              </div>
              <div
                className="col-md-4 m-cardaddress"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <i className="fa fa-phone" aria-hidden="true" /> <br />
                <p className="mt-3">+62 21 2765 8985</p>
              </div>
              <div
                className="col-md-4 m-cardaddress"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <i className="fa fa-envelope" aria-hidden="true" /> <br />
                <p className="mt-3">sales@atw-solar.id</p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-6 img-showroom-m">
                <img
                  src="assets/img/img tutorial redential/picture9.png"
                  className="img-fluid"
                  data-aos="zoom-in"
                  data-aos-delay={100}
                  alt="true"
                />
              </div>
              <div className="col-md-6">
                <h1
                  className="text-center pt-5"
                  data-aos="zoom-in"
                  data-aos-delay={200}
                >
                  {props.contactUS.visit}
                </h1>
                <div
                  className="row p-3"
                  data-aos="zoom-in"
                  data-aos-delay={300}
                >
                  <div className="col-md-2 showroom1-m">
                    <img
                      src="assets/img/img tutorial redential/address.png"
                      className="img-fluid"
                      alt="true"
                    />
                  </div>
                  <div className="col-md-10 showroom2-m">
                    <p className="alamat">
                      Jl. Darmawangsa VI No.31, RT.5/RW.1, Pulo, Kec. Kby. Baru,
                      Kota Jakarta Selatan.
                    </p>
                    <p className="tlp">087828976527</p>
                  </div>
                </div>
                <div
                  className="row p-3"
                  data-aos="zoom-in"
                  data-aos-delay={400}
                >
                  <div className="col-md-2 showroom1-m">
                    <img
                      src="assets/img/img tutorial redential/address.png"
                      className="img-fluid"
                      alt="true"
                    />
                  </div>
                  <div className="col-md-10 showroom2-m">
                    <p className="alamat">
                      Setrasari Mall, Jl. Terusan Sutami No.72, Sukagalih,
                      Sukajadi, Bandung City
                    </p>
                    <p className="tlp">085745820674</p>
                  </div>
                </div>
                <div
                  className="row p-3"
                  data-aos="zoom-in"
                  data-aos-delay={500}
                >
                  <div className="col-md-2 showroom1-m">
                    <img
                      src="assets/img/img tutorial redential/address.png"
                      className="img-fluid"
                      alt="true"
                    />
                  </div>
                  <div className="col-md-10 showroom2-m">
                    <p className="alamat">
                      Ruko North Junction Blok RA-25 Citraland, Jl. Taman Puspa
                      Raya, Sambikerep, Surabaya.
                    </p>
                    <p className="tlp">085745820674</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-0">
          <div className="m-auto text-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.075803678303!2d106.79670102288073!3d-6.253743248471112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f19e9a441985%3A0x369baa99245dec42!2sATW%20SOLAR%20-%20SOLARISTIC%20SHOWROOM!5e0!3m2!1sid!2sid!4v1676365197881!5m2!1sid!2sid"
              className="frame-map"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Map"
            />
          </div>
        </section>
        <section
          className="section1"
          data-aos="fade-up"
          data-aos-delay={200}
          style={{ position: "relative" }}
        >
          <h1 className="text-center">{props.contactUS.letsAnswer}</h1>
          <div className="container">
            <form>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="mb-2 label-text "
                    >
                      {props.contactUS.labelFirst}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control mb-2 "
                      id="firstname"
                      name="firstname"
                      onChange={onChangeName}
                      value={inputForm.firstName}
                      placeholder={props.contactUS.labelFirst}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="mb-2 label-text"
                    >
                      {props.contactUS.labelLast}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control mb-2 "
                      id="lastname"
                      name="lastname"
                      onChange={onChangeLastName}
                      value={inputForm.lastName}
                      placeholder={props.contactUS.labelLast}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="mb-2 label-text"
                    >
                      {props.contactUS.labelNoTlp}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control mb-2 "
                      id="tlp"
                      name="tlp"
                      onChange={onChangeTlp}
                      value={inputForm.noTlp}
                      placeholder={props.contactUS.labelNoTlp}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="mb-2 label-text"
                    >
                      E-MAIL <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control mb-2 "
                      id="email"
                      name="email"
                      onChange={onChangeEmail}
                      value={inputForm.email}
                      placeholder="E-Mail"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  {" "}
                  <div className="form-group">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="mb-2 label-text"
                    >
                      {props.contactUS.labelMessage}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      name="message"
                      className="form-control mb-2 "
                      id="message"
                      cols={10}
                      rows={11}
                      onChange={onChangeMessage}
                      value={inputForm.message}
                      defaultValue={""}
                    />
                  </div>
                  <button
                    className="btn btn-primary button-loading"
                    type="button"
                    disabled
                    style={{
                      float: "right",
                      display: `${spinnerLoading.nameDisplaySpinner}`,
                    }}
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </button>
                  <button
                    type="button"
                    id="submit"
                    className="btn btn-primary mt-2 button-primari"
                    onClick={sendEmail}
                    style={{
                      float: "right",
                      display: `${spinnerLoading.nameDisplaySubmit}`,
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
      {/* End #main */}
    </div>
  );
}
const mapStateHeaderProps = (state) => {
  return {
    home: state.home,
    service: state.service,
    residentialNav: state.residentialNav,
    commercialNav: state.commercialNav,
    term: state.term,
    privacy: state.privacy,
    contact: state.contact,
    contactUS: {
      labelContact: state.contactUS.labelContact,
      getInTouch: state.contactUS.getInTouch,
      titleContact: state.contactUS.titleContact,
      contentContact: state.contactUS.contentContact,
      visit: state.contactUS.visit,
      letsAnswer: state.contactUS.letsAnswer,
      labelFirst: state.contactUS.labelFirst,
      labelLast: state.contactUS.labelLast,
      labelNoTlp: state.contactUS.labelNoTlp,
      labelMessage: state.contactUS.labelMessage,
    },
  };
};

export default connect(mapStateHeaderProps)(Contact);
