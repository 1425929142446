import { React, useEffect } from "react";
import "../assets/css/style.css";
import AOS from "aos";
import "aos/dist/aos.css";
import imgAlat from "../assets/img/img tutorial redential/picture_alat.png";
import { connect } from "react-redux";

function Landing(props) {
  useEffect(() => {
    document.title = "SolarAccess - One stop solution to monitor your Solar PV System";
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <div>
      <div>
        {/* ======= Hero Section ======= */}
        <section id="hero" className="align-items-center hero-landing-m">
          <div className="row container navbar-solar-text">
            <div className="col-md-6 text-access" data-aos="zoom-out">
              <h1 id="welcome">
                {props.welcome}
                <span style={{ color: "#055C9D" }}>SolarAccess</span>
              </h1>
              <h2 id="oneStop">{props.oneStop}</h2>
            </div>
            <div className="col-md-5 text-center m-image" data-aos="zoom-out">
              <div className style={{ position: "relative" }}>
                <img
                  src="assets/img/img tutorial redential/picture10.png"
                  className="img-fluid img-mobile-nav"
                  alt="true"
                />
                <img
                  src={imgAlat}
                  className="img-fluid img-mobile-device"
                  alt="true"
                />
              </div>
            </div>
          </div>
        </section>
        {/* End Hero */}
        <main id="main">
          {/* ======= Featured Services Section ======= */}
          <section id="featured-services" className="featured-services">
            <div className="container" data-aos="fade-up">
              <div className="about-solar">
                <h1 className="text-center" id="aboutsolar">
                  {props.titleAbout}
                </h1>
                <div className="row about-solar-access">
                  <div className="col-md-6">
                    <p className="about-text mt-4 " id="aboutsolar1">
                      {props.contentAbout}
                    </p>
                  </div>
                  <div className="col-md-6 aboutsolarimg-m">
                    <img
                      src="assets/img/galery3.png"
                      className="img-fluid img-aboutsolar"
                      alt="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Featured Services Section */}

          {/* ======= Services Section ======= */}
          <section id="services" className="services">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h1 id="aboutlayanan">{props.titleService}</h1>
              </div>
              <div className="row">
                <div
                  className="col-lg-6 col-md-6 d-flex align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay={100}
                >
                  <div className="icon-box">
                    <div className="icon">
                      <i className="bx bxl-dribbble" />
                    </div>
                    <h4>
                      <a href="residential.html" id="aboutlayanan1">
                        {props.titleService2}
                      </a>
                    </h4>
                    <p id="aboutlayanan2">{props.contentResidential}</p>
                    <a
                      href="residential.html"
                      className="mt-2"
                      style={{ float: "right", color: "#303F6E" }}
                    >
                      Detail &gt;&gt;
                    </a>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                  data-aos="zoom-in"
                  data-aos-delay={200}
                >
                  <div className="icon-box">
                    <div className="icon">
                      <i className="bx bx-file" />
                    </div>
                    <h4>
                      <a href="commercial&industrial.html" id="aboutlayanan3">
                        {props.titleCommercial}
                      </a>
                    </h4>
                    <p id="aboutlayanan4">{props.contentCommercial}</p>
                    <a
                      href="commercial&industrial.html"
                      className="mt-2"
                      style={{ float: "right", color: "#303F6E" }}
                    >
                      Detail &gt;&gt;
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Services Section */}
          <section id="featured-services" className="featured-services">
            <div className="container" data-aos="fade-up">
              <div className="about-solar">
                <h1 className="text-center" id="profit">
                  {props.titleBenefit}
                </h1>
                <div className="row keuntungan-solar-access">
                  <div className="col-md-5 img-keuntungan">
                    <img
                      src="assets/img/img tutorial redential/picture20.png"
                      className="img-fluid img-aboutsolar"
                      alt="true"
                    />
                  </div>
                  <div className="col-md-7">
                    <p className="about-text mt-4 " id="profit1">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Vero iusto consectetur, minus soluta ea ut perspiciatis
                      quod totam, rem repellat maiores ipsam eaque ad sed
                      blanditiis fugiat expedita corrupti aliquid. Lorem ipsum
                      dolor sit amet consectetur adipisicing elit. Obcaecati
                      architecto dolore quo dicta dolor, aspernatur cum
                      quibusdam vitae ab ad velit a non modi officia
                      reprehenderit exercitationem incidunt adipisci iure.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Featured Services Section */}
          {/* ======= Clients Section ======= */}
          <section id="clients" className="clients">
            <div className="container" data-aos="zoom-in">
              <h1 className="text-center" id="client">
                {props.titleClient}
              </h1>
              <div className="row">
                <div className="col-lg-3 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img
                    src="assets/img/clients/client-1.png"
                    className="img-fluid"
                    alt="true"
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img
                    src="assets/img/clients/client-2.png"
                    className="img-fluid"
                    alt="true"
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img
                    src="assets/img/clients/client-3.png"
                    className="img-fluid"
                    alt="true"
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img
                    src="assets/img/clients/client-4.png"
                    className="img-fluid"
                    alt="true"
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img
                    src="assets/img/clients/client-5.png"
                    className="img-fluid"
                    alt="true"
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img
                    src="assets/img/clients/client-6.png"
                    className="img-fluid"
                    alt="true"
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img
                    src="assets/img/clients/client-6.png"
                    className="img-fluid"
                    alt="true"
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img
                    src="assets/img/clients/client-6.png"
                    className="img-fluid"
                    alt="true"
                  />
                </div>
              </div>
            </div>
          </section>
          {/* End Clients Section */}
          {/* ======= Frequently Asked Questions Section ======= */}
          <section id="faq" className="faq ">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h1 id="answer">{props.titleFaq}</h1>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <ul className="faq-list">
                    <li>
                      <div
                        data-bs-toggle="collapse"
                        className="collapsed question"
                        href="#faq1"
                        id="faaq1"
                      >
                        {props.titleFaq1}
                        <i className="bi bi-chevron-down icon-show" />
                        <i className="bi bi-chevron-up icon-close" />
                      </div>
                      <div
                        id="faq1"
                        className="collapse"
                        data-bs-parent=".faq-list"
                      >
                        <p id="faaq2">{props.contentFaq1}</p>
                      </div>
                    </li>
                    <li>
                      <div
                        data-bs-toggle="collapse"
                        href="#faq2"
                        className="collapsed question"
                        id="faaq3"
                      >
                        
                        {props.titleFaq2}
                        <i className="bi bi-chevron-down icon-show" />
                        <i className="bi bi-chevron-up icon-close" />
                      </div>
                      <div
                        id="faq2"
                        className="collapse"
                        data-bs-parent=".faq-list"
                      >
                        <p id="faaq4">{props.contentFaq2}</p>
                      </div>
                    </li>
                    <li>
                      <div
                        data-bs-toggle="collapse"
                        href="#faq3"
                        className="collapsed question"
                        id="faaq5"
                      >
                        
                        {props.titleFaq3}
                        <i className="bi bi-chevron-down icon-show" />
                        <i className="bi bi-chevron-up icon-close" />
                      </div>
                      <div
                        id="faq3"
                        className="collapse"
                        data-bs-parent=".faq-list"
                      >
                        <p></p>
                        <ol>
                          <li id="faaq7"> {props.contentFaq3_1} </li>
                          <li id="faaq8"> {props.contentFaq3_2} </li>
                          <li id="faaq9"> {props.contentFaq3_3} </li>
                          <li id="faaq10"> {props.contentFaq3_4} </li>
                          <li id="faaq11"> {props.contentFaq3_5} </li>
                          <li id="faaq12"> {props.contentFaq3_6} </li>
                          <li id="faaq13"> {props.contentFaq3_7} </li>
                        </ol>
                        <p />
                      </div>
                    </li>
                    <li>
                      <div
                        data-bs-toggle="collapse"
                        href="#faq4"
                        className="collapsed question"
                        id="faaq14"
                      >
                        {props.titleFaq4}
                        <i className="bi bi-chevron-down icon-show" />
                        <i className="bi bi-chevron-up icon-close" />
                      </div>
                      <div
                        id="faq4"
                        className="collapse"
                        data-bs-parent=".faq-list"
                      >
                        <span className="mb-5" id="faaq15"></span>
                        <p>{props.contentFaq4_1}</p>
                        <ol>
                          <li id="faaq16">
                            {props.contentFaq4_2} <b>{props.contentFaq4_3} </b>
                          </li>
                          <li id="faaq17">{props.contentFaq4_4}</li>
                          <li id="faaq18">{props.contentFaq4_5}</li>
                          <li id="faaq19">{props.contentFaq4_6}</li>
                          <li id="faaq20">{props.contentFaq4_7}</li>
                        </ol>
                        <p />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          {/* End Frequently Asked Questions Section */}
        </main>
        {/* End #main */}
      </div>
    </div>
  );
}

const mapStateHeaderProps = (state) => {
  return {
    welcome: state.landing.welcome,
    oneStop: state.landing.oneStop,
    titleAbout: state.landing.titleAbout,
    contentAbout: state.landing.contentAbout,
    titleService: state.landing.titleService,
    titleService2: state.landing.titleService2,
    contentResidential: state.landing.contentResidential,
    titleCommercial: state.landing.titleCommercial,
    contentCommercial: state.landing.contentCommercial,
    titleBenefit: state.landing.titleBenefit,
    titleClient: state.landing.titleClient,
    titleFaq: state.landing.titleFaq,
    titleFaq1: state.landing.titleFaq1,
    contentFaq1: state.landing.contentFaq1,
    titleFaq2: state.landing.titleFaq2,
    contentFaq2: state.landing.contentFaq2,
    titleFaq3: state.landing.titleFaq3,
    contentFaq3_1: state.landing.contentFaq3_1,
    contentFaq3_2: state.landing.contentFaq3_2,
    contentFaq3_3: state.landing.contentFaq3_3,
    contentFaq3_4: state.landing.contentFaq3_4,
    contentFaq3_5: state.landing.contentFaq3_5,
    contentFaq3_6: state.landing.contentFaq3_6,
    contentFaq3_7: state.landing.contentFaq3_7,
    titleFaq4: state.landing.titleFaq4,
    contentFaq4_1: state.landing.contentFaq4_1,
    contentFaq4_2: state.landing.contentFaq4_2,
    contentFaq4_3: state.landing.contentFaq4_3,
    contentFaq4_4: state.landing.contentFaq4_4,
    contentFaq4_5: state.landing.contentFaq4_5,
    contentFaq4_6: state.landing.contentFaq4_6,
    contentFaq4_7: state.landing.contentFaq4_7,
    titleContact: state.landing.titleContact,
    contentContact: state.landing.contentContact,
  };
};
export default connect(mapStateHeaderProps)(Landing);
