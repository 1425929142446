import React, { useState } from "react";
import "../assets/css/style.css";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import logoInggris from "../assets/img/img tutorial redential/english.jpg";
import logoIndonesia from "../assets/img/img tutorial redential/indonesia.jpg";

function Header(props) {
  const [navbarMobile, setNavbarMobile] = useState("navbar");
  const [displayMobile, setDisplayMobile] = useState({ display: "none" });
  const [dropdown, setDropDown] = useState("");
  const onChangeBar = () => {
    setNavbarMobile("navbar navbar-mobile");
    setDisplayMobile({
      ...displayMobile,
      display: "block",
    });
  };
  const onChangeBarExit = () => {
    setNavbarMobile("navbar");
    setDisplayMobile({
      ...displayMobile,
      display: "none",
    });
  };
  const onChangeDisplay = () => {
    setNavbarMobile("navbar");
    setDisplayMobile({
      ...displayMobile,
      display: "none",
    });
  };
  const onChangeDropDown = () => {
    if (dropdown === "") {
      setDropDown("dropdown-active");
    } else {
      setDropDown("");
    }
  };
  return (
    <div>
      {/* ======= Header ======= */}
      <header id="header" className="d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">
          <h1 className="logo web-logo">
            <NavLink
              to="/"
              className="nav-link scrollto"
              onClick={onChangeDisplay}
            >
              
              <img src="assets/img/solarAccess_logo.png" alt="true" />
            </NavLink>
          </h1>
          <h1 className="logo mobile-logo">
            <NavLink
              to="/"
              className="nav-link scrollto"
              onClick={onChangeDisplay}
            >
              
              <img src="assets/img/solarAccess_logo_A.png" alt="true" />
            </NavLink>
          </h1>
          <nav id="navbar" className={navbarMobile}>
            <ul>
              <li>
                <NavLink
                  to="/"
                  className="nav-link scrollto"
                  onClick={onChangeDisplay}
                >
                  {props.home}
                </NavLink>
              </li>
              <li className="dropdown" onClick={onChangeDropDown}>
                <a href="#/">
                  <span id="navservice">{props.service}</span>
                  <i className="bi bi-chevron-down" />
                </a>
                <ul className={dropdown}>
                  <li>
                    <NavLink
                      to="/residential"
                      className="nav-link scrollto "
                      onClick={onChangeDisplay}
                    >
                      {props.residentialNav}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/commercial"
                      className="nav-link scrollto "
                      onClick={onChangeDisplay}
                    >
                      {props.commercialNav}
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  to="/term-and-condition"
                  className="nav-link scrollto "
                  onClick={onChangeDisplay}
                >
                  {props.term}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/privacy-and-policy"
                  className="nav-link scrollto "
                  onClick={onChangeDisplay}
                >
                  {props.privacy}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  className="nav-link scrollto "
                  onClick={onChangeDisplay}
                >
                  {props.contact}
                </NavLink>
              </li>
              <ol className="language">
                <li style={{ display: "inline-block",cursor:'pointer' }}>
                  <a href="#/"
                    className="nav-link  language-hover"
                    onClick={props.languageEnglish}
                    style={{ display: "inline-block" }}
                  >
                    <img
                      src={logoInggris}
                      className="img-fluid"
                      width={20}
                      style={{ paddingRight: 5 }}
                      alt="true"
                    />
                    Eng
                  </a>
                  ||
                </li>
                <li style={{ display: "inline-block", cursor:'pointer' }}>
                  <a href="#/"
                    className="nav-link language-hover"
                    onClick={props.languageIndonesia}
                    data-reload
                    style={{ display: "inline-block" }}
                  >
                    <img
                      src={logoIndonesia}
                      className="img-fluid"
                      width={20}
                      style={{ paddingRight: 5 }}
                      alt="true"
                    />
                    IDN
                  </a>
                </li>
              </ol>
            </ul>
            <i className="bi bi-list mobile-nav-toggle" onClick={onChangeBar} />
            <i
              className="bi bi-list mobile-nav-toggle bi-x"
              onClick={onChangeBarExit}
              style={{ display: `${displayMobile.display}` }}
            />
          </nav>
          {/* .navbar */}
        </div>
      </header>
      {/* End Header */}
    </div>
  );
}
const mapStateHeaderProps = (state) => {
  return {
    home: state.home,
    service: state.service,
    residentialNav: state.residentialNav,
    commercialNav: state.commercialNav,
    term: state.term,
    privacy: state.privacy,
    contact: state.contact,
  };
};
const mapDispatchHeaderProps = (dispatch) => {
  return {
    languageEnglish: () => dispatch({ type: "ENGLISH" }),
    languageIndonesia: () => dispatch({ type: "INDONESIA" }),
  };
};
export default connect(mapStateHeaderProps, mapDispatchHeaderProps)(Header);
